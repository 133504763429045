import consts from '../../main/consts'

const initialState = {
    type: null,
    occurrenceCreated: null,
    unitList: []    
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.OCCURRENCE_TYPE_FETCHED: return { ...state, type: action.payload }
        case consts.REDUCERS.OCCURRENCE_CREATED: return { ...state, occurrenceCreated: action.payload }
        case consts.REDUCERS.UNITS_FETCHED: return { ...state, unitList: action.payload.data }
        default: return state
    }
}    
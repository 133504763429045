import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

import consts from '../../main/consts'
import { selectTab, showTabs } from '../../components/tab/TabActions'
import { getAllActiveTypes } from '../occurrence-type/OccurrenceTypeActions'
import { getAllActiveSubjects } from '../subject/SubjectActions'
import { api } from '../../main/api'

export function init() {
    return [
        showTabs('tabRegister'),
        selectTab('tabRegister'),
        getUnitsList(),
        getAllActiveTypes(),
        getAllActiveSubjects(),
        setOccurrenceCreated(null),
        initialize('registerForm', null)
    ]
}

export function setType(type) {
    return {
        type: consts.REDUCERS.OCCURRENCE_TYPE_FETCHED,
        payload: type
    }
}

export function getUnitsList() {
    const request = api.get(`${consts.CONFIG.API_URL}/care-units`)
    return {
        type: consts.REDUCERS.UNITS_FETCHED,
        payload: request
    }
}

export function submit(values) {
    return dispatch => {
        values.subject = typeof (values.subject) === 'string' ? JSON.parse(values.subject) : values.subject
        values.factDate = new Date(values.factDate).toISOString()
        api.post(`${consts.CONFIG.API_URL}/occurrences`, values)
            .then(resp => {
                dispatch(showResult(resp.data))
            })
            .catch(e => {
                toastr.error('Erro', e.response.data.error)
            })
    }
}

export function setOccurrenceCreated(occurrence) {
    return {
        type: consts.REDUCERS.OCCURRENCE_CREATED,
        payload: occurrence
    }
}

export function showResult(occurrence) {
    return [
        setOccurrenceCreated(occurrence),
        setType(null),
        showTabs('tabResult'),
        selectTab('tabResult')
    ]
}
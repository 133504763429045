import { combineReducers } from 'redux'
import { reducer as FormReducer } from 'redux-form'
import { reducer as ToastrReducer } from 'react-redux-toastr'

import LoadingReducer from '../components/loading/LoadingReducer'
import TabReducer from '../components/tab/TabReducer'
import ModalReducer from '../components/modal/ModalReducer'
import AuthReducer from '../components/auth/AuthReducer'
import TemplateReducer from '../components/template/TemplateReducer'
import PublicConsultReducer from '../components/public-consult/PublicConsultReducer'
import RegisterReducer from '../pages/register/RegisterReducer'
import MyWorkReducer from '../pages/my-work/MyWorkReducer'
import OccurrenceTypeReducer from '../pages/occurrence-type/OccurrenceTypeReducer'
import SubjectReducer from '../pages/subject/SubjectReducer'
import DashboardReducer from '../pages/dashboard/DashboardReducer'
import StatisticReportReducer from '../pages/statistic-report/StatisticReportReducer'

export default combineReducers({
    form: FormReducer,
    toastr: ToastrReducer,
    loading: LoadingReducer,
    tab: TabReducer,
    modal: ModalReducer,
    auth: AuthReducer,
    template: TemplateReducer,
    publicConsult: PublicConsultReducer,
    register: RegisterReducer,
    myWork: MyWorkReducer,
    occurrenceType: OccurrenceTypeReducer,
    subject: SubjectReducer,
    dashboard: DashboardReducer,
    statisticReport: StatisticReportReducer
})
import jwt_decode from 'jwt-decode'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import consts from '../../main/consts'
import { api } from '../../main/api'
import { showForm, submit } from './MyWorkActions'

class BoxCard extends Component {

    renderActionLabelByStatus(status) {
        if (status === 'WAI') {
            return <>Iniciar análise <i className="fa fa-arrow-circle-right" /></>
        }
        return <>Mais informações <i className="fa fa-arrow-circle-up" /></>
    }

    getQuestionsList(id) {
        return api.get(`${consts.CONFIG.API_URL}/questions/by-occurrence/${id}`)
            .then(resp => { return resp.data })
    }

    getStoriesList(id) {
        return api.get(`${consts.CONFIG.API_URL}/stories/by-occurrence/${id}`)
            .then(resp => { return resp.data })
    }

    getUnit(careUnit) {
        return api.get(`${consts.CONFIG.API_URL}/care-units/${careUnit}`)
            .then(resp => { return resp.data })
    }    

    getCitizen(citizen) {
        if (!citizen) { return }

        const headers = { Authorization: `Bearer ${this.props.user.access_token}` }
        return api.get(`${consts.CONFIG.AUTH_API_URL}/${citizen}`, { headers })
            .then(resp => { return resp.data })
    }

    async onClickActionByStatus(occurrence) {
        if (occurrence.status === 'WAI') {
            const loggedUser = jwt_decode(this.props.user.access_token)
            const storie = {
                description: "Iniciando a análise",
                occurrence: { id: occurrence.id },
                status: "ANL"
            }

            occurrence.ombudsman = loggedUser.preferred_username
            occurrence.status = "ANL"
            await api.post(`${consts.CONFIG.API_URL}/stories`, storie)
            this.props.submit(occurrence)
        } else {
            const questions = await this.getQuestionsList(occurrence.id)
            const stories = await this.getStoriesList(occurrence.id)
            const unit = await this.getUnit(occurrence.careUnit)
            const citizen = await this.getCitizen(occurrence.citizen)

            this.props.showForm({ ...occurrence, questions, stories, unit, citizen })
        }
    }

    render() {
        const { occurrence } = this.props
        const createdAt = new Date(occurrence.createdAt).toLocaleString()
        return (
            <div className={`small-box bg-${occurrence.subject.type.color.toLowerCase()}`}>
                <div className="inner">
                    <strong>{occurrence.subject.type.description.toUpperCase()}</strong>
                    <span className="pull-right">{occurrence.protocol.toUpperCase()}</span>
                    <p>{occurrence.subject.description}</p>
                    <small>Registrado em: {createdAt}</small>
                </div>
                <a
                    className="small-box-footer"
                    style={{ cursor: "pointer" }}
                    onClick={() => this.onClickActionByStatus(occurrence)}
                >
                    {this.renderActionLabelByStatus(occurrence.status)}
                </a>
            </div>
        )
    }

}

const mapStateToProps = state => ({ user: state.auth.user })
const mapDispatchToProps = dispatch => bindActionCreators({ showForm, submit }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BoxCard)
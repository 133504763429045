import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector, change } from 'redux-form'

import consts from '../../main/consts'
import TabContent from '../../components/tab/TabContent'
import LabelAndInput from '../../components/form/LabelAndInput'
import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import { api } from '../../main/api'

const MyWorkTabForward = props => {
    const [state, setState] = useState({
        email: "",
        description: ""
    })

    const postNewStorie = async _ => {
        if (props.questions[0]) { return }

        const storie = {
            description: "Iniciando a análise e tratamento",
            occurrence: { id: props.id },
            status: "TRE"
        }
        await api.post(`${consts.CONFIG.API_URL}/stories`, storie)
    }

    const submitNewQuestion = async _ => {
        const question = {
            ...state,
            occurrence: { id: props.id },
            responseURL: `${window.location.origin}/#/question/`
        }

        if (document.getElementById('myWorkForm').checkValidity()) {
            await props.change("myWorkForm", "status", "TRE")
            await postNewStorie()
            await api.post(`${consts.CONFIG.API_URL}/questions`, question)
        }
    }

    return (
        <TabContent id="tabForward">
            <div className="box-body no-padding">
                <LabelAndInput
                    name="email"
                    label="E-mail"
                    type="email"
                    input={{ onChange: e => setState({ ...state, email: e.target.value }) }}
                    placeholder="Preencha este campo com o e-mail do profissional desejado"
                    required
                    cols="12"
                />
                <LabelAndTextarea
                    name="description"
                    label="Questionamento"
                    input={{ onChange: e => setState({ ...state, description: e.target.value }) }}
                    placeholder="Escreva seu questionamento aqui"
                    required
                    rows="10"
                    cols="12"
                />
            </div>
            <div className="box-footer no-padding">
                <div className="pull-right">
                    <button
                        disabled={!(state.email && state.description)}
                        type="submit"
                        className="btn btn-success btn-sm"
                        onClick={submitNewQuestion}
                    >
                        <i className="fa fa-paper-plane-o" />
                        Encaminhar
                    </button>
                </div>
            </div>
        </TabContent>
    )
}

const selector = formValueSelector("myWorkForm")
const mapStateToProps = state => ({
    id: selector(state, "id"),
    questions: selector(state, "questions")
})
const mapDispatchToProps = dispatch => bindActionCreators({ change }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyWorkTabForward)
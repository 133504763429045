import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Row from '../../components/layout/Row'
import TabContent from '../../components/tab/TabContent'
import Tabs from '../../components/tab/Tabs'
import TabsContent from '../../components/tab/TabsContent'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import Form from './StatisticReportForm'
import List from './StatisticReportResult'
import { init, submit } from './StatisticReportActions'

class StatisticReport extends Component {

    componentDidMount() {
        this.props.init()
    }

    render() {
        return (
            <div>
                <ContentHeader
                    title="Relatório"
                    subtitle="Estatístico de atendimento de ocorrências"
                />
                <Content>
                    <Row>
                        <Tabs>
                            <TabsContent>
                                <TabContent id="tabFilter">
                                    <Form onSubmit={this.props.submit} />
                                </TabContent>
                                <TabContent id="tabList">
                                    <List />
                                </TabContent>
                            </TabsContent>
                        </Tabs>
                    </Row>
                </Content>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({ init, submit }, dispatch)

export default connect(null, mapDispatchToProps)(StatisticReport)
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import consts from '../../main/consts'

import Row from '../../components/layout/Row'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import LabelAndText from '../../components/form/LabelAndText'
import LabelAndValue from '../../components/form/LabelAndValue'
import LabelAndFormatedDate from '../../components/form/LabelAndFormatedDate'
import If from '../../components/operator/If'
import { Link } from 'react-router-dom'

const OccurrenceDetails = props => {
    const [state] = useState(props.formValues || {})

    function renderTable() {
        return (
            <If test={state.questions}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Data de envio</th>
                            <th>Data da resposta</th>
                            <th>E-mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </If>
        )
    }

    function renderRows() {
        const list = state.questions || []

        return list.map(quest => (
            <tr key={quest.id}>
                <td>{new Date(quest.createdAt).toLocaleString()}</td>
                <td>{quest.answer ? new Date(quest.answerDate).toLocaleString() : ""}</td>
                <td>{quest.email}</td>
            </tr>
        ))
    }

    return (
        <div>
            <ContentHeader
                title="Versão para impressão"
                subtitle="Detalhes da ocorrência"
            />
            <Content>
                <Row>
                    <div className="box box-primary">
                        <div className="box-header">
                            <h3 className="box-title">Dados da ocorrência</h3>
                        </div>
                        <div className='box-body'>
                            <LabelAndValue
                                input={{ value: state.protocol }}
                                label='Protocolo'
                                cols='12 6'
                            />
                            <LabelAndValue
                                input={{ value: state.validationKey }}
                                label='Chave'
                                cols='12 6'
                            />                            
                            <LabelAndValue
                                input={{ value: state.unit.description }}
                                label='Unidade'
                                cols='12'
                            />
                            <LabelAndValue
                                input={{ value: state.local }}
                                label='Local'
                                cols='12'
                            />
                            <LabelAndFormatedDate
                                input={{ value: state.factDate }}
                                label='Data do fato'
                                cols='12 6'
                            />
                            <LabelAndFormatedDate
                                input={{ value: state.createdAt }}
                                label='Data de registro'
                                cols='12 6'
                            />
                            <LabelAndValue
                                input={{ value: state.subject ? state.subject.type.description : '' }}
                                label='Tipo'
                                cols='12'
                            />
                            <LabelAndValue
                                input={{ value: state.subject ? state.subject.description : '' }}
                                label='Assunto'
                                cols='12'
                            />
                            <LabelAndText
                                input={{ value: state.description }}
                                label='Descrição'
                                rows='5'
                                cols='12'
                            />
                        </div>
                    </div>
                    <div className={"box box-secundary"}>
                        <div className="box-header">
                            <h3 className="box-title">Questões</h3>
                        </div>
                        <div className='box-body'>
                            {renderTable()}
                        </div>
                    </div>
                    <div className={`box box-${state.status === 'DON' ? 'success' : 'warning'}`}>
                        <div className="box-header">
                            <h3 className="box-title">Situação</h3>
                        </div>
                        <div className='box-body'>
                            <LabelAndValue
                                input={{ value: consts.OCCURRENCE_STATUS[state.status].text }}
                                label='Status'
                                cols='12 4'
                            />
                            <LabelAndFormatedDate
                                input={{ value: state.updatedAt }}
                                label='Última atualização'
                                cols='12 8'
                            />
                            <If test={state.status === 'DON'}>
                                <LabelAndText
                                    label="Resposta"
                                    cols="12"
                                    rows="5"
                                    input={{ value: state.answer }}
                                />
                            </If>
                        </div>
                    </div>
                    <div className='box-footer no-padding' style={{ marginBottom: "30px" }}>
                    <Link
                        type='button'
                        className='btn btn-default'
                        to="my-work">
                        <i className="fa fa-undo" />
                        Voltar
                    </Link>
                    <button
                        type="button"
                        className='btn btn-info'
                        onClick={() => window.print()}>
                        <i className="fa fa-print" />
                        Imprimir
                    </button>
                </div>
                </Row>
            </Content>
        </div>
    )
}

const mapStateToProps = state => ({ formValues: getFormValues("myWorkForm")(state) })

export default connect(mapStateToProps)(OccurrenceDetails)
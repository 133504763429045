import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    const requiredFlag = props.required ? '*': ''
    props.input.value = typeof(props.input.value) === 'object' ?
        JSON.stringify(props.input.value) : props.input.value
    return (
        <Grid cols={props.cols}>
            <div className='form-group'>
            <label htmlFor={props.name}>{`${props.label}${requiredFlag}`}:</label>
                <select
                    {...props.input}
                    className={`form-control ${props.className}`}
                    placeholder={props.placeholder}
                    disabled={props.readOnly}
                    required={props.required}
                >
                    {props.children}
                </select>
            </div>
        </Grid>
    )
}

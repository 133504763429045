import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import consts from '../../main/consts'
import Grid from '../../components/layout/Grid'
import Row from '../../components/layout/Row'
import { init } from './StatisticReportActions'

const StatisticReportResult = props => {
    const { filters } = props
    const groupBy = filters.groupBy === "type, status" ? "Tipo de ocorrência - Status" : "Status - Tipo de ocorrência"
    const dateResume = new Date(filters.initialDateTime).toLocaleString() + " à " + new Date(filters.finalDateTime).toLocaleString()
    const filtersResume = "Período de " + dateResume
    let finalTotal = 0

    function renderRows() {
        const list = props.data || []

        return list.map(item => {
            const { total, average } = item
            const agroupment = filters.groupBy === "type, status" ?
                `${item.type} - ${consts.OCCURRENCE_STATUS[item.status].text}` : `${consts.OCCURRENCE_STATUS[item.status].text} - ${item.type}`
            
            finalTotal += total
            return {
                agroupment,
                total,
                average: average ? average.substring(0, average.indexOf('.')).replace('days', 'dias') : ''
            }
        })
        .sort(function (a, b) {
            if (a.agroupment > b.agroupment) { return 1 }
            if (a.agroupment < b.agroupment) { return -1 }
            return 0
        })
        .map(ot => {
            return (
                <tr key={`${ot.agroupment}`}>
                    <td>{ot.agroupment}</td>
                    <td>{ot.total}</td>
                    <td>{ot.average}</td>
                </tr>
            )
        })
    }

    return (
        <Row>
            <Grid cols="12">
                <div className="box">
                    <div className="box-header">
                        <p className="no-margin"><small>{filtersResume}</small></p>
                    </div>
                    <div className="box-body table-responsive no-padding">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Agrupamento ({groupBy})</th>
                                    <th>Quantidade</th>
                                    <th>Média de tempo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRows()}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="text-right">Total de ocorrências no período: </th>
                                    <td>{finalTotal}</td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div className="box-footer clearfix">
                        <div className="pull-right">
                            <button
                                type="button"
                                className={"btn btn-default"}
                                onClick={props.init}>
                                <i className="fa fa-undo" />
                                Voltar
                            </button>
                            <button
                                type="button"
                                className="btn btn-info"
                                onClick={() => window.print()}>
                                <i className="fa fa-print" />
                                Imprimir
                            </button>
                        </div>
                    </div>
                </div>
            </Grid>
        </Row>
    )
}

const mapStateToProps = state => ({
    data: state.statisticReport.data,
    filters: state.statisticReport.filters
})
const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(StatisticReportResult)
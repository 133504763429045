import consts from '../../main/consts'

const initialState = {
    list: [],
    question: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.OCCURRENCES_FETCHED: return { ...state, list: action.payload.data }
        case consts.REDUCERS.QUESTION_FETCHED: return { ...state, question: action.payload }
        default: return state
    }
}    
import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    const dateToFormat = props.input.value ? new Date(props.input.value).toLocaleString() : ""
    return (
        <Grid cols={props.cols}>
            <label
                className={props.className}
                style={{ marginRight: "5px"}}
            >
                {props.label}:
            </label>
                {dateToFormat}
        </Grid>
    )
}

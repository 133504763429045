import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import Grid from '../../components/layout/Grid'
import Row from '../../components/layout/Row'
import PublicConsultVideo from '../../assets/public_consult.gif'

const RegisterResult = props => {
    const { dto } = props

    return (
        <Row className="text-center">
            <Grid cols="12">
                <div className="box box-success">
                    <div className="box-header text-center no-padding">
                        <Grid cols="12 6">
                            <h3>Procotolo: <strong>{dto.protocol}</strong></h3>
                        </Grid>
                        <Grid cols="12 6">
                            <h3>Chave: <strong>{dto.validationKey}</strong></h3>
                        </Grid>
                    </div>
                    <div className="box-body no-padding text-center">
                        <p>Como consultar:</p>
                        <img src={PublicConsultVideo}
                            alt="Acompanhe seu processo - vídeo"
                            style={{ maxWidth: "-webkit-fill-available" }}
                        />
                    </div>
                    <div className="box-footer no-padding">
                        <Link
                            type='button'
                            className='btn btn-default'
                            to="/"
                            style={{ margin: "3px" }}
                        >
                            <i className="fa fa-undo" />
                            Voltar
                        </Link>
                    </div>
                </div>
            </Grid>
        </Row>
    )
}

const mapStateToProps = state => ({ dto: state.register.occurrenceCreated })

export default connect(mapStateToProps)(RegisterResult)
import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    const requiredFlag = props.required ? '*': ''
    const onInvalid = props.msgInvalidPattern ? e => e.target.setCustomValidity(props.msgInvalidPattern) : props.onInvalid
    const onInput = props.msgInvalidPattern ? e => e.target.setCustomValidity('') : props.onInput
    return (
        <Grid cols={props.cols}>
            <div className='form-group'>
                <label htmlFor={props.name}>{`${props.label}${requiredFlag}`}:</label>
                <textarea
                    {...props.input}
                    className={`form-control ${props.className}`}
                    style={props.style}
                    type={props.type}
                    rows={props.rows}
                    placeholder={props.placeholder}
                    readOnly={props.readOnly}
                    minLength={props.minLength}
                    maxLength={props.maxLength}
                    required={props.required}
                    pattern={props.pattern}
                    onInvalid={onInvalid}
                    onInput={onInput}
                />
            </div>
        </Grid>
    )
}

import consts from '../../main/consts'

const initialState = {
    filters: {},
    data: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.STATISTIC_FILTERS_FETCHED: return { ...state, filters: action.payload }
        case consts.REDUCERS.STATISTIC_DATA_FETCHED: return { ...state, data: action.payload }
        default: return state
    }
}    
import consts from '../../main/consts'

const INITIAL_STATE = {
    user: JSON.parse(localStorage.getItem(consts.CONFIG.TOKEN_REF))
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case consts.REDUCERS.USER_FETCHED:
            localStorage.setItem(consts.CONFIG.TOKEN_REF, JSON.stringify(action.payload))
            return { ...state, user: action.payload }
        default:
            return state
    }
}
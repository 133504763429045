import consts from '../../main/consts'

const initialState = {
    dto: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.OCCURRENCE_FETCHED: return { ...state, dto: action.payload }
        default: return state
    }
}
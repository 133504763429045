import React from 'react'
import Auth from '../../components/auth/Auth'
import PublicConsult from '../../components/public-consult/PublicConsult'
import QuickAccess from '../../components/quick-access/QuickAccess'

export default () =>
    <>
        <Auth />
        <PublicConsult />
        <QuickAccess />
    </>
import { toastr } from 'react-redux-toastr'

import consts from '../../main/consts'
import { api } from '../../main/api'

const { tokenUrl, clientId, clientSecret } = consts.CONFIG.KEYCLOAK
const keyCloakClientCredentials = `client_id=${clientId}&client_secret=${clientSecret}`

export async function getSystemToken() {
    const values = `${keyCloakClientCredentials}&grant_type=client_credentials`

    return await api.post(tokenUrl, values)
        .then(resp => { return resp.data })
        .catch(_ => { return null })
}

export async function refreshToken(token) {
    if (!token) { return await getSystemToken() }

    const values = `${keyCloakClientCredentials}&grant_type=refresh_token&refresh_token=${token.refresh_token}`
    const newToken = await api.post(tokenUrl, values)
        .then(resp => { return {...token, ...resp.data } })
        .catch(async () => { return await getSystemToken() })

    logRefreshToken(token, newToken)
    return newToken
}

function logRefreshToken(oldUser, newUser) {
    if (!consts.CONFIG.DEBUG_LOG) { return }

    const oldTokenSize = oldUser.access_token ? oldUser.access_token.length : null
    const oldToken = `${oldUser.access_token ? oldUser.access_token.substring(oldTokenSize - 3, oldTokenSize) : "NULO"} ${oldUser.userToken ? '(U)' : '(S)'}`
    const newTokenSize = newUser.access_token.length
    const newToken = `${newUser.access_token.substring(newTokenSize - 3, newTokenSize)} ${newUser.userToken ? '(U)' : '(S)'}`

    console.log(`### TOKEN REFRESHED: ${oldToken} -> ${newToken} ###`);
}

async function getUserId(cpf) {
    return api.post(`${consts.CONFIG.AUTH_API_URL}/find-username`, { type: "cpf", value: cpf })
}

async function saveUser(values) {
    const newUser = { ...values, roles: { cms: ["citizen"] } }

    return api.post(consts.CONFIG.AUTH_API_URL, newUser)
}

export function addToken(token) {
    return { type: consts.REDUCERS.USER_FETCHED, payload: token }
}

function submit(id, password) {
    const values = `${keyCloakClientCredentials}&grant_type=password&username=${id}&password=${password}`

    return dispatch => {
        api.post(tokenUrl, values)
            .then(resp => dispatch(addToken({ ...resp.data, userToken: true })))
            .catch(_ => toastr.error('Erro', "Credenciais inválidas"))
    }
}

export function login(values) {
    return async dispatch => {
        await getUserId(values.attributes.cpf)
            .then(resp => { dispatch(submit(resp.data, values.password)) })
            .catch(_ => toastr.error('Erro', "Credenciais inválidas"))
    }
}

export function signup(values) {
    return async dispatch => {
        if (values.password != values.confirm) {
            toastr.error('Erro', "Senhas não são iguais")
            return
        }

        await saveUser(values)
            .then(resp => { dispatch(submit(resp.data.userName, values.password)) })
            .catch(error => {
                const { message } = error.response.data
                if (message && message === "409 Conflict: User exists with same email") {
                    toastr.error('Erro', "E-mail já cadastrado ! Caso não consiga entrar, clique em Esqueci minha senha!")
                    return
                }
                toastr.error('Erro', "Erro ao tentar salvar usuário")
            })
    }
}

export function logout() {
    return async dispatch => {
        const newToken = await getSystemToken()
        dispatch(addToken(newToken))
    }
}
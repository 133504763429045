import consts from '../../main/consts'

const initialState = {
    showModalForm: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.MODAL_SHOW: return { ...state, showModalForm: action.payload }
        case consts.REDUCERS.MODAL_HIDE: return { ...state, showModalForm: action.payload }
        default: return state
    }
}    
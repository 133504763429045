import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm, formValueSelector, change } from 'redux-form'
import { Link } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

import LabelAndInput from '../../components/form/LabelAndInput'
import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import LabelAndSelect from '../../components/form/LabelAndSelect'
import { formatDate } from '../../main/utils'
import { setType } from './RegisterActions'

let RegisterForm = props => {

    const setLoggedUser = async () => {
        const { user } = props
        if (user.userToken) {
            const loggedUser = jwt_decode(user.access_token)
            await props.change("citizen", loggedUser.preferred_username)
        }
    }

    const renderTypesList = () => {
        const { type } = props
        if (type) {
            return <option key={`type-${type.id}`} value={JSON.stringify(type)}>{type.description}</option>
        }

        return props.typeList.map((tp, i) => {
            if (i === 0) {
                return <React.Fragment key="type-first">
                    <option key="type-null" value="" disabled>Selecione...</option>
                    <option key={`type-${tp.id}`} value={JSON.stringify(tp)}>{tp.description}</option>
                </React.Fragment>
            }
            return <option key={`type-${tp.id}`} value={JSON.stringify(tp)}>{tp.description}</option>
        })
    }

    const renderSubjectsList = () => {
        const type = props.type ? props.type : props.occurrenceType ? JSON.parse(props.occurrenceType) : null
        if (!type) return

        return props.subjectList
            .filter(sub => { return sub.type.id == type.id })
            .map(sub => { return <option key={`sub-${sub.id}`} value={JSON.stringify(sub)}>{sub.description}</option> })
    }

    const renderUnitsList = () => {
        const list = props.unitList || []
        return list
            .sort(function (a, b) {
                if (a.description > b.description) { return 1 }
                if (a.description < b.description) { return -1 }
                return 0
            })        
            .map(unit => {
                return <option key={`unit-${unit.id}`} value={unit.id}>{unit.description}</option>
            })
    }

    return (
        <form onSubmit={props.handleSubmit}>
            <div className="box  box-primary">
                <div className="box-header">
                    <p><strong>Conte-nos o que aconteceu</strong></p>
                </div>
                <div className="box-body no-padding">
                    <Field
                        name='occurrenceType'
                        component={LabelAndSelect}
                        label='Tipo de ocorrência'
                        cols='12 4'
                        required
                        readOnly={props.type}
                    >
                        {renderTypesList()}
                    </Field>
                    <Field
                        name='subject'
                        component={LabelAndSelect}
                        label='Assunto'
                        cols='12 8'
                        required
                        readOnly={!props.occurrenceType && !props.type}
                    >
                        <option key="sub-null" value="" disabled>Selecione...</option>
                        {renderSubjectsList()}
                    </Field>
                    <Field
                        name='description'
                        component={LabelAndTextarea}
                        style={{ resize: 'none' }}
                        label='Descrição'
                        cols='12'
                        rows='5'
                        required
                    />
                </div>
            </div>
            <div className="box  box-primary">
                <div className="box-header">
                    <p><strong>Onde isso aconteceu ?</strong></p>
                </div>
                <div className="box-body no-padding">
                    <div>
                        <Field
                            name='factDate'
                            component={LabelAndInput}
                            label='Data do fato'
                            cols='12 4'
                            required
                            type="datetime-local"
                            max={formatDate(new Date())}
                        />
                    </div>
                    <Field
                        name='careUnit'
                        component={LabelAndSelect}
                        label='Unidade'
                        cols='12'
                        required
                    >
                        <option key="unit-null" value="" disabled>Selecione...</option>
                        {renderUnitsList()}
                    </Field>
                    <Field
                        name='local'
                        component={LabelAndInput}
                        label='Local'
                        cols='12'
                        required
                    />
                </div>
            </div>
            <div className="no-padding">
                <Link
                    type='button'
                    className='btn btn-default'
                    to="/"
                    style={{ margin: "3px" }}
                    onClick={() => props.setType(null)}
                >
                    <i className="fa fa-undo" />
                    Voltar
                </Link>
                <div className="pull-right">
                    <button
                        type="submit"
                        className={"btn btn-success"}
                        style={{ margin: "3px" }}
                        onClick={setLoggedUser}
                    >
                        <i className="fa fa-save" />
                        Confirmar
                    </button>
                </div>
            </div>
        </form>
    )

}

RegisterForm = reduxForm({ form: 'registerForm' })(RegisterForm)
const selector = formValueSelector('registerForm')
const mapStateToProps = state => ({
    occurrenceType: selector(state, 'occurrenceType'),
    user: state.auth.user,
    type: state.register.type,
    typeList: state.occurrenceType.typesActiveList,
    subjectList: state.subject.subjectsActiveList,
    unitList: state.register.unitList
})
const mapDispatchToProps = dispatch => bindActionCreators({ change, setType }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm)
import React from 'react'
import { connect } from 'react-redux'
import { Field, formValueSelector } from 'redux-form'

import Label from '../../components/form/Label'
import LabelAndFormatedDate from '../../components/form/LabelAndFormatedDate'
import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import LabelAndValue from '../../components/form/LabelAndValue'
import Text from '../../components/form/Text'
import If from '../../components/operator/If'
import TabContent from '../../components/tab/TabContent'

const MyWorkTabCore = props => {
    return (
        <TabContent id="tabCore">
            <div className='box-body no-padding'>
                <Field
                    name='protocol'
                    component={LabelAndValue}
                    label='Protocolo'
                    cols='12 7'
                />
                <Field
                    name='validationKey'
                    component={LabelAndValue}
                    label='Chave'
                    cols='12 5'
                />
                <Field
                    name='unit.description'
                    component={LabelAndValue}
                    label='Unidade'
                    cols='12'
                />
                <Field
                    name='local'
                    component={LabelAndValue}
                    label='Local'
                    cols='12'
                />
                <Field
                    name='factDate'
                    component={LabelAndFormatedDate}
                    label='Data do fato'
                    cols='12'
                />
                <Field
                    name='createdAt'
                    component={LabelAndFormatedDate}
                    label='Data da criação'
                    cols='12'
                />
                <Field
                    name='updatedAt'
                    component={LabelAndFormatedDate}
                    label='Data da última atualização'
                    cols='12'
                />
                <Field
                    name='subject.type.description'
                    component={Label}
                    className={`badge bg-${props.color}`}
                    cols='12'
                    style={{ marginTop: "10px" }}
                />
                <Field
                    name='subject.description'
                    component={Text}
                    cols='12'
                />
                <Field
                    name='description'
                    component={LabelAndTextarea}
                    style={{ resize: 'none' }}
                    label='Descrição'
                    rows='5'
                    cols='12'
                    readOnly
                />
                <If test={props.status === "DON"}>
                    <Field
                        name='answer'
                        component={LabelAndTextarea}
                        style={{ resize: 'none' }}
                        label='Resposta enviada ao cidadão'
                        rows='5'
                        cols='12'
                        readOnly
                    />
                </If>
            </div>
        </TabContent>
    )
}

const selector = formValueSelector('myWorkForm')
const mapStateToProps = state => ({
    color: selector(state, 'subject.type.color'),
    status: selector(state, 'status'),
})

export default connect(mapStateToProps)(MyWorkTabCore)
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Row from '../../components/layout/Row'
import TabContent from '../../components/tab/TabContent'
import Tabs from '../../components/tab/Tabs'
import TabsContent from '../../components/tab/TabsContent'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import { create, init, remove, update } from './OccurrenceTypeActions'
import List from './OccurrenceTypeList'
import Form from './OccurrenceTypeForm'

class OccurrenceType extends Component {

    componentWillMount() {
        this.props.init()
    }

    render() {
        return (
            <div>
                <ContentHeader
                    title="Tipos de ocorrência"
                    subtitle=""
                />
                <Content>
                    <Row>
                        <Tabs>
                            <TabsContent>
                                <TabContent id="tabList">
                                    <List />
                                </TabContent>
                                <TabContent id="tabCreate">
                                    <Form
                                        onSubmit={this.props.create}
                                        submitLabel='Incluir'
                                        submitClass='primary'
                                        createMode
                                    />
                                </TabContent>
                                <TabContent id="tabUpdate">
                                    <Form
                                        onSubmit={this.props.update}
                                        submitLabel='Alterar'
                                        submitClass='info'
                                        updateMode
                                    />
                                </TabContent>
                                <TabContent id="tabDelete">
                                    <Form
                                        onSubmit={this.props.remove}
                                        submitLabel='Excluir'
                                        submitClass='danger'
                                        readOnly
                                    />
                                </TabContent>
                            </TabsContent>
                        </Tabs>
                    </Row>
                </Content>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => bindActionCreators({
    init, create, update, remove
}, dispatch)

export default connect(null, mapDispatchToProps)(OccurrenceType)
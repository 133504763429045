import React, { Component } from 'react'
import jwt_decode from 'jwt-decode'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './Custom.css'
import '../dependencies'
import App from './App'
import Public from './Public'
import consts from './consts'
import { api } from './api'
import { refreshToken, addToken } from '../components/auth/AuthActions'
import { showLoading, hideLoading } from '../components/loading/LoadingActions'

class PublicOrApp extends Component {

    componentWillMount() {
        this.configureApi()
    }

    configureApi() {
        api.interceptors.request.use(async (config) => {
            const user = this.props.auth.user || {}
            this.props.showLoading()

            if (!config._retry) {
                config.headers.Authorization = `Bearer ${user.access_token}`
            }
            if (config.url.includes(consts.CONFIG.API_URL)) {
                config.headers["X-TenantID"] = window.location.hostname.split(".")[0]
            }

            this.logRequest(config);
            return config;
        }, (error) => {
            this.props.hideLoading()

            return Promise.reject(error);
        });

        api.interceptors.response.use((response) => {
            this.props.hideLoading()

            return response;
        }, async (error) => {
            const user = this.props.auth.user || {}
            const originalRequest = error.config;
            const response = error.response;

            if (response && response.status === 401 && !originalRequest._retry) {
                const newToken = await refreshToken(user)
                originalRequest.headers.Authorization = `Bearer ${newToken.access_token}`
                originalRequest._retry = true

                this.props.addToken(newToken)
                return api.request(originalRequest)
            }

            this.props.hideLoading()
            return Promise.reject(error);
        });
    }

    getDecodedTokens() {
        const { user } = this.props.auth
        if (!user) { return }

        const access = jwt_decode(user.access_token)
        const refresh = jwt_decode(user.refresh_token)
        return { access, refresh }
    }

    logRequest(config) {
        const { user } = this.props.auth

        if (consts.CONFIG.DEBUG_LOG && user) {
            const { refresh } = this.getDecodedTokens()
            const expDate = new Date(refresh.exp * 1000).toLocaleString()
            const tokenSize = user.access_token.length
            const token = user.access_token.substring(tokenSize - 3, tokenSize)

            console.log(`${new Date().toLocaleString()}: ${config.url}${config._retry ? ' (R)' : ''} | ${token} ${user.userToken ? '(U)' : '(S)'} | ${expDate}`);
        }
    }

    render() {
        const user = this.props.auth.user || {}

        if (user.userToken) {
            const { access, refresh } = this.getDecodedTokens()
            const { roles } = access.resource_access.cms

            const loggedUser = {
                ...access,
                isOmbudsman: consts.ROLES.OMBUDSMAN.includes(roles),
                isCitizen: consts.ROLES.CITIZEN.includes(roles),
                expDate: new Date(refresh.exp * 1000).toLocaleString()
            }

            return <App user={loggedUser}>{this.props.children}</App>
        } else {
            return <Public />
        }
    }

}

const mapStateToProps = state => ({ auth: state.auth })
const mapDispatchToProps = dispatch => bindActionCreators({
    refreshToken, addToken, showLoading, hideLoading
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PublicOrApp)
import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import TabContent from '../../components/tab/TabContent'

const MyWorkTabComplainer = props => {
    const citizen = props.citizen || {}
    const attributes = citizen.attributes || {}

    return (
        <TabContent id="tabComplainer">
            <div className='box-body no-padding'>
                <p><strong>Nome: </strong>{citizen.firstName}</p>
                <p><strong>E-mail: </strong>{citizen.email}</p>
                <p><strong>CPF: </strong>{attributes.cpf}</p>
            </div>
        </TabContent>
    )
}

const selector = formValueSelector("myWorkForm")
const mapStateToProps = state => ({
    citizen: selector(state, "citizen")
})

export default connect(mapStateToProps)(MyWorkTabComplainer)
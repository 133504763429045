import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { hideModal } from './ModalActions'
import If from '../operator/If'

class Modal extends Component {

    render() {
        const display = this.props.showModalForm ? "block" : "none"

        return (
            <div className="modal fade in" style={{ display }}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <If test={!this.props.hideHeader}>
                        <div className="modal-header">
                            <h4 className="modal-title">{this.props.title}</h4>
                            <button
                                type="button"
                                className="close"
                                onClick={this.props.hideModal}
                            >
                                <span className="text-primary">X</span>
                            </button>                            
                        </div>
                        </If>
                        <div className="modal-body" style={this.props.bodyStyle}>
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    showModalForm: state.modal.showModalForm
})
const mapDispatchToProps = dispatch => bindActionCreators({ hideModal }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
import consts from '../../main/consts'
import { api } from '../../main/api'

const ENDPOINT = "/dashboard"

export function getDashboardValues() {
    const request = api.get(`${consts.CONFIG.API_URL}${ENDPOINT}`)
    return {
        type: consts.REDUCERS.DASHBOARD_FETCHED,
        payload: request
    }
}
import React from 'react'
import Logo from '../../assets/logo_celk.png'
import Navbar from './Navbar'
import If from '../operator/If'

export default props => (
    <header className="main-header">
        <a href="https://www.celk.com.br" className="logo">
            <span className='logo-mini'>
                <img src={Logo} alt="Logo Celk" />
            </span>
            <span className="logo-lg">
                <img src={Logo} alt="Logo Celk" />
            </span>
        </a>
        <nav className={`navbar navbar-static-top ${props.user ? '' : 'hidden-xs'}`}>
            <If test={props.user}>
                <a className='sidebar-toggle' data-toggle='offcanvas' />
                <Navbar {...props} />
            </If>
        </nav>
    </header>
)
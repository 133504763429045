import React, { useState } from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { login, signup } from './AuthActions'

import Messages from '../message/Message'
import Input from '../form/InputAuth'
// import TenantLogo from '../../components/tenant-logo/TenantLogo'

let Auth = props => {
    const [loginMode, setLoginMode] = useState(true)
    const { handleSubmit } = props

    async function onSubmit(values) {
        loginMode ? await props.login(values) : await props.signup(values)
    }

    return (
        <div className="login-box">
            <div className="login-logo no-margin">
                {/* <TenantLogo /> */}
                <p className="no-margin">Ouvidoria da Saúde</p>
            </div>
            <div className="login-box-body no-padding">
                <form onSubmit={handleSubmit(v => onSubmit(v))}>
                    <Field
                        component={Input}
                        type="input"
                        name="firstName"
                        placeholder="Nome"
                        icon='user'
                        required={!loginMode}
                        hide={loginMode} />
                    <Field
                        component={Input}
                        name="attributes.cpf"
                        placeholder="CPF"
                        required
                        maxLength="11"
                        pattern="[0-9]{11}"
                        msgInvalidPattern="Digite apenas os números do seu CPF!"
                        icon='user' />
                    <Field
                        component={Input}
                        type="email"
                        name="email"
                        placeholder="E-mail"
                        icon='envelope'
                        required={!loginMode}
                        hide={loginMode} />
                    <Field
                        component={Input}
                        type="password"
                        name="password"
                        placeholder="Senha"
                        required
                        icon='lock' />
                    <Field
                        component={Input}
                        type="password"
                        name="confirm"
                        placeholder="Confirmar Senha"
                        icon='lock'
                        required={!loginMode}
                        hide={loginMode} />
                    <button
                        type="submit"
                        className="btn btn-primary btn-block btn-flat">
                        {loginMode ? 'Acessar conta' : 'Registrar'}
                    </button>
                </form>
                {/* <a style={{ display: displayPassForgot }}>Esqueci a senha</a> */}
                <a onClick={() => setLoginMode(!loginMode)}>
                    {loginMode ? 'Não possui conta? CADASTRAR' : 'Já é cadastrado ? Entre aqui !'}
                </a>
            </div>
            <Messages />
        </div>
    )
}

Auth = reduxForm({ form: 'authForm' })(Auth)
const mapDispatchToProps = dispatch => bindActionCreators({ login, signup }, dispatch)

export default connect(null, mapDispatchToProps)(Auth)
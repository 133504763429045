import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { formValueSelector, change, submit } from 'redux-form'
import { Link } from 'react-router-dom'

import consts from '../../main/consts'
import If from '../../components/operator/If'
import { api } from '../../main/api'
import { changeTab, init } from './MyWorkActions'

const MyWorkActionButtons = props => {

    const questions = props.questions || []
    const notAnswered = questions.filter(question => !question.answer)
    const actionButtonColor = props.status === "ANL" ? "red" : notAnswered.length > 0 ? "yellow" : "green"
    const actionButtonIcon = props.status === "ANL" ? "exclamation" : notAnswered.length > 0 ? "exclamation" : "thumbs-o-up"
    const actionButtonText = props.status === "ANL" ? "Finalizar sem tratar" : notAnswered.length > 0 ? "Finalizar tratativas sem resposta(s)" : "Finalizar tratativas"

    const changeStatusToFinalized = async _ => {
        const storie = {
            description: "Iniciando a finalização",
            occurrence: { id: props.id },
            status: "FIN"
        }

        await props.change("myWorkForm", "status", "FIN")
        await api.post(`${consts.CONFIG.API_URL}/stories`, storie)
        props.submit("myWorkForm")
    }

    return (
        <>
            <div className="box-header">
                <div className="pull-right">
                    <button
                        type="button"
                        className="close"
                        onClick={props.init}
                    >
                        <span className="text-black">X</span>
                    </button>
                </div>
                <div className="btn-group">
                    <button type="button" className="btn btn-primary btn-sm">
                        Informações
                    </button>
                    <button type="button" className="btn btn-primary btn-sm dropdown-toggle"
                        data-toggle="dropdown" aria-expanded="false">
                        <span className="caret" />
                        <span className="sr-only" />
                    </button>
                    <ul className="dropdown-menu" role="menu" style={{ textAlign: "right", cursor: "pointer" }}>
                        <li><a onClick={() => props.changeTab('tabCore')}>
                            <i className="fa fa-bullhorn" />
                            Ínicio
                        </a></li>
                        <If test={props.citizen}>
                            <li><a onClick={() => props.changeTab('tabComplainer')}>
                                <i className="fa fa-id-card-o" />
                                Dados do cidadão
                            </a></li>
                        </If>
                        <li><a onClick={() => props.changeTab('tabStories')}>
                            <i className="fa fa-calendar" />
                            Histórico
                        </a></li>
                        <If test={questions[0]}>
                            <li><a onClick={() => props.changeTab('tabQuestions')}>
                                <i className="fa fa-question-circle-o" />
                                Questões enviadas
                            </a></li>
                        </If>
                    </ul>
                </div>
                <div className="btn-group">
                    <button type="button" className="btn btn-danger btn-sm">
                        Ações
                </button>
                    <button type="button" className="btn btn-danger btn-sm dropdown-toggle"
                        data-toggle="dropdown" aria-expanded="false">
                        <span className="caret" />
                        <span className="sr-only" />
                    </button>
                    <ul className="dropdown-menu" role="menu" style={{ textAlign: "right", cursor: "pointer" }}>
                        <li><Link to="print-version">
                            <i className="fa fa-print" />
                            Imprimir
                        </Link></li>
                        <If test={["ANL", "TRE", "FIN"].includes(props.status)}>
                            <li className="divider" />
                        </If>
                        <If test={["ANL", "TRE"].includes(props.status)}>
                            <li><a onClick={() => props.changeTab('tabForward')}>
                                <i className="fa fa-paper-plane-o" />
                                <strong>Enviar questão</strong>
                            </a></li>
                            <li><a className={`text-${actionButtonColor}`}
                                onClick={changeStatusToFinalized}>
                                <i className={`fa fa-${actionButtonIcon}`} />
                                {actionButtonText}
                            </a></li>
                        </If>
                        <If test={["FIN"].includes(props.status)}>
                            <li><a className="text-green"
                                onClick={() => props.changeTab('tabAnswer')}>
                                <i className="fa fa-gavel" />
                            Responder ao cidadão
                        </a></li>
                        </If>
                    </ul>
                </div>
            </div>
        </>
    )
}

const selector = formValueSelector("myWorkForm")
const mapStateToProps = state => ({
    id: selector(state, "id"),
    status: selector(state, "status"),
    questions: selector(state, "questions"),
    citizen: selector(state, "citizen")
})
const mapDispatchToProps = dispatch => bindActionCreators({
    init, changeTab, change, submit
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyWorkActionButtons)
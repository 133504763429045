import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { setQuestion, setQuestionAndUpdate } from './MyWorkActions'

import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import LabelAndValue from '../../components/form/LabelAndValue'
import If from '../../components/operator/If'
import TabContent from '../../components/tab/TabContent'

const MyWorkTabQuestions = props => {

    function renderTable() {
        return (
            <If test={!props.question.description}>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th className="status-column"></th>
                            <th>Data</th>
                            <th>E-mail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </If>
        )
    }

    function renderRows() {
        const list = props.list || []

        return list.map(quest => (
            <tr key={quest.id}>
                <td><a onClick={_ => quest.hasOwnProperty('readAnswerDate') ?
                    props.setQuestion(quest) : props.setQuestionAndUpdate(quest)}>
                    <i className={`fa fa-eye text-${quest.answer ? 'green' : 'yellow'}`} />
                </a>
                </td>
                <td>{new Date(quest.createdAt).toLocaleString()}</td>
                <td>{quest.email}</td>
            </tr>
        ))
    }

    function renderQuestion() {
        return (
            <If test={props.question.description}>
                <LabelAndValue
                    name="data"
                    label="Data"
                    readOnly
                    cols='12 6'
                    input={{ value: new Date(props.question.createdAt).toLocaleString() }}
                />
                <LabelAndValue
                    name="email"
                    label="E-mail"
                    readOnly
                    cols='12 6'
                    input={{ value: props.question.email }}
                />
                <LabelAndTextarea
                    name="description"
                    label="Questionamento"
                    style={{ resize: 'none' }}
                    readOnly
                    rows='10'
                    cols='12'
                    input={{ value: props.question.description }}
                />
                <LabelAndTextarea
                    name="answer"
                    label="Resposta"
                    style={{ resize: 'none' }}
                    readOnly
                    rows='10'
                    cols='12'
                    input={{ value: props.question.answer }}
                />
                <div className="pull-right">
                    <button type='button'
                        className='btn btn-default'
                        onClick={() => props.setQuestion({})}>
                        <i className="fa fa-undo" />
                        Voltar
                    </button>
                </div>
            </If>
        )
    }

    return (
        <TabContent id="tabQuestions">
            <div className='box-body no-padding'>
                {renderTable()}
                {renderQuestion()}
            </div>

        </TabContent>
    )

}

const selector = formValueSelector('myWorkForm')
const mapStateToProps = state => ({
    list: selector(state, 'questions'),
    question: state.myWork.question
})
const mapDispatchToProps = dispatch => bindActionCreators({ setQuestion, setQuestionAndUpdate }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyWorkTabQuestions)
import consts from '../../main/consts'

const initialState = {
    notReadAnswerList: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.NOT_READ_ANSWER_FETCHED: return { ...state, notReadAnswerList: action.payload.data }
        default: return state
    }
}    
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import Row from '../../components/layout/Row'
import TabContent from '../../components/tab/TabContent'
import Tabs from '../../components/tab/Tabs'
import TabsContent from '../../components/tab/TabsContent'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'

import { init, submit } from './RegisterActions'
import Form from './RegisterForm'
import Result from './RegisterResult'

class Register extends Component {

    componentDidMount() {
        this.props.init()
    }

    render() {
        return (
            <div>
                <ContentHeader
                    title={`${this.props.occurrenceCreated ? "Acesso" : "Registrar ocorrência"}`}
                    subtitle=""
                />
                <Content>
                    <Row>
                        <Tabs>
                            <TabsContent>
                                <TabContent id="tabRegister">
                                    <Form onSubmit={this.props.submit} />
                                </TabContent>
                                <TabContent id="tabResult">
                                    <Result />
                                </TabContent>
                            </TabsContent>
                        </Tabs>
                    </Row>
                    
                </Content>
            </div>
        )
    }

}

const mapStateToProps = state => ({ occurrenceCreated: state.register.occurrenceCreated })
const mapDispatchToProps = dispatch => bindActionCreators({ init, submit }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Register)
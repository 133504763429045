import consts from '../../main/consts'

export function showLoading() {
    return {
        type: consts.REDUCERS.LOADING,
        payload: true
    }
}

export function hideLoading() {
    return {
        type: consts.REDUCERS.LOADING,
        payload: false
    }
}
import React from 'react';
import { HashRouter } from 'react-router-dom';

import Footer from '../components/template/Footer';
import Header from '../components/template/Header';
import Sidebar from '../components/template/Sidebar';
import Message from '../components/message/Message';
import Loading from '../components/loading/Loading';
import Routes from './AppRoutes';

export default props =>
    <HashRouter>
        <div className="wrapper">
            <Header {...props} />
            <Sidebar {...props} />
            <Routes {...props} />
            <Footer />
            <Message />
            <Loading />
        </div>
    </HashRouter>
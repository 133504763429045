import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getDashboardValues } from './DashboardActions'

import Row from '../../components/layout/Row'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import InfoBox from '../../components/info-box/InfoBox'
import Grid from '../../components/layout/Grid'
import ProgressGroup from '../../components/progress/ProgressGroup'
import { Doughnut } from 'react-chartjs-2'

class Dashboard extends Component {

    componentWillMount() {
        this.props.getDashboardValues()
    }

    getPercent(value, total) {
        return ((value / total) * 100).toFixed(2)
    }

    getTotalByStatus(list, status) {
        return list
            .filter(item => item.status === status)
            .reduce((sum, counter) => sum + counter.total, 0)
    }

    getStatusCounter(dto) {
        const list = dto.countByStatus || []
        const waiting = this.getTotalByStatus(list, "WAI")
        const working = this.getTotalByStatus(list, "ANL") + this.getTotalByStatus(list, "TRE")
        const finished = this.getTotalByStatus(list, "FIN")
        const done = this.getTotalByStatus(list, "DON")
        const total = waiting + working + finished + done

        return {
            waiting: { number: waiting, percent: this.getPercent(waiting, total) },
            working: { number: working, percent: this.getPercent(working, total) },
            finished: { number: finished, percent: this.getPercent(finished, total) },
            done: { number: done, percent: this.getPercent(done, total) },
            total
        }
    }

    renderTopFiveSubjects(dto) {
        const list = dto.countTopFiveSubjects || []
        const total = list.reduce((sum, counter) => sum + counter.total, 0)

        return list.map((sub, i) =>
            <ProgressGroup
                key={sub.subject}
                text={`#${i + 1} - ${sub.subject}`}
                detail={sub.type}
                number={sub.total}
                color={sub.color}
                percent={this.getPercent(sub.total, total)}
            />
        )
    }

    getDataGraphCountByType(dto) {
        const list = dto.countByType || []
        let labels = []
        let data = []
        let backgroundColor = []
        list.forEach(item => {
            labels.push(item.type)
            backgroundColor.push(item.color)
            data.push(item.total)
        })

        return {
            labels,
            datasets: [{
                backgroundColor,
                data
            }]
        }
    }

    render() {
        const dto = this.props.dto || {}
        const { waiting, working, finished, done } = this.getStatusCounter(dto)
        
        return (
            <div>
                <ContentHeader
                    title="Dashboard"
                    subtitle=""
                />
                <Content>
                    <Row>
                        <InfoBox
                            cols="12 6 3"
                            color="purple"
                            icon="clock-o"
                            text="Aguardando"
                            number={waiting.number}
                            percent={waiting.percent}
                            description={`${waiting.percent.replace('.',',')}%`}
                        />
                        <InfoBox
                            cols="12 6 3"
                            color="yellow"
                            icon="briefcase"
                            text="Em andamento"
                            number={working.number}
                            percent={working.percent}
                            description={`${working.percent.replace('.',',')}%`}
                        />
                        <InfoBox
                            cols="12 6 3"
                            color="aqua"
                            icon="bell-o"
                            text="Finalizado"
                            number={finished.number}
                            percent={finished.percent}
                            description={`${finished.percent.replace('.',',')}%`}
                        />
                        <InfoBox
                            cols="12 6 3"
                            color="green"
                            icon="trophy"
                            text="Concluído"
                            number={done.number}
                            percent={done.percent}
                            description={`${done.percent.replace('.',',')}%`}
                        />
                        <Grid cols="12 12 6">
                            <div className="box box-primary">
                                <div className="box-header">
                                    <p className="text-center"><strong>TOP 5 ASSUNTOS</strong></p>
                                </div>
                                <div className="box-body">
                                    {this.renderTopFiveSubjects(dto)}
                                </div>
                            </div>
                        </Grid>
                        <Grid cols="12 12 6">
                            <div className="box box-warning">
                                <div className="box-header">
                                    <p className="text-center"><strong>GRÁFICO - OCORRÊNCIAS POR TIPO</strong></p>
                                </div>
                                <div className="box-body" style={{height: '275px'}}>
                                    <Doughnut
                                        data={this.getDataGraphCountByType(dto)}
                                        options={{
                                            legend: { position: 'bottom' },
                                            responsive: true,
                                            maintainAspectRatio: false
                                        }}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Row>
                </Content>
            </div>
        )
    }

}

const mapStateToProps = state => ({ dto: state.dashboard.dto })
const mapDispatchToProps = dispatch => bindActionCreators({
    getDashboardValues
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
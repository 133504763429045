import React from 'react'
import consts from '../../main/consts'

import If from '../operator/If'

const Timeline = props => {

    const render = _ => {
        const list = props.list || []
        let lastDate

        return list.map(time => {
            const printDate = lastDate != new Date(time.createdAt).toLocaleDateString()
            lastDate = new Date(time.createdAt).toLocaleDateString()

            return (
            <React.Fragment key={time.id}>
                <If test={printDate}>
                    <li className="time-label">
                        <span className="bg-gray">
                            {new Date(time.createdAt).toLocaleDateString()}
                        </span>
                    </li>
                </If>
                <li>
                    <i
                        className={`fa fa-${consts.OCCURRENCE_STATUS[time.status].icon} bg-${consts.OCCURRENCE_STATUS[time.status].color}`}
                        data-toggle="tooltip" data-placement="bottom" title={consts.OCCURRENCE_STATUS[time.status].text}
                    />
                    <div className="timeline-item">
                        <span className="time">
                            <i className="fa fa-clock-o" />
                            {new Date(time.createdAt).toLocaleTimeString()}
                        </span>
                        <div className="timeline-body">
                            {time.description}
                        </div>
                    </div>
                </li>
            </React.Fragment>
        )})
    }

    return (
        <ul className="timeline">
            {render()}
        </ul>
    )
}

export default Timeline
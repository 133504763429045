import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Grid from '../../components/layout/Grid'
import { getList, showForm } from './OccurrenceTypeActions'
import Row from '../../components/layout/Row'

class OccurrenceTypeList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            page: 1,
            description: ''
        }
    }

    componentWillMount() {
        this.updatePage()
    }

    renderRows(dto) {
        const list = dto.pageList || []
        return list.map(ot => (
            <tr key={ot.id}>
                <td><i className={`fa fa-${ot.active ? 'unlock-alt' : 'lock'}`} /></td>
                <td><span className={`badge bg-${ot.color} text-${ot.color}`}>-</span></td>
                <td>{ot.description}</td>
                <td>
                    <button
                        className="btn btn-warning btn-sm"
                        onClick={() => this.props.showForm('tabUpdate', ot)}
                    >
                        <i className='fa fa-pencil' />
                    </button>
                    <button
                        className="btn btn-danger btn-sm"
                        onClick={() => this.props.showForm('tabDelete', ot)}
                    >
                        <i className='fa fa-trash-o' />
                    </button>
                </td>
            </tr>
        ))
    }

    updatePage() {
        const { description, page } = this.state
        this.props.getList(description, page)
    }

    keyHandler = e => {
        if (e.key === 'Enter') {
            this.updatePage()
        }
    }

    render() {
        const dto = this.props.dto || {}
        const totalPages = dto.totalPages || 1
        const totalRecords = dto.totalRecords || 0

        return (
            <Row>
                <Grid cols="12">
                    <div className="box">
                        <div className="box-header">
                            <button
                                className="btn btn-primary btn-sm"
                                onClick={() => this.props.showForm('tabCreate', null)}
                            >
                                Novo
                            </button>
                            <div className="pull-right">
                                <div className="input-group input-group-sm" style={{ width: "170px" }}>
                                    <input
                                        type="search"
                                        className="form-control input-sm"
                                        placeholder="Digite aqui para procurar"
                                        value={this.state.description}
                                        onChange={e => this.setState({ description: e.target.value })}
                                        onKeyDown={e => this.keyHandler(e)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="box-body table-responsive no-padding">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th className="status-column">#</th>
                                        <th className="color-column">Cor</th>
                                        <th>Descrição</th>
                                        <th className="table-actions">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.renderRows(dto)}
                                </tbody>
                            </table>
                        </div>
                        <div className="box-footer clearfix">
                            <div className="pull-left">
                                <label style={{ marginTop: "3px" }}>
                                    Total de {totalRecords} registros.
                                </label>
                            </div>
                            <div className="pull-right">
                                <label>
                                    Página
                                    <input
                                        id="paginator"
                                        type="number"
                                        min={1}
                                        max={totalPages}
                                        value={this.state.page}
                                        disabled={totalPages === 1}
                                        onKeyDown={e => e.preventDefault()}
                                        style={{ margin: "0px 5px" }}
                                        onChange={e => {
                                            this.setState({ page: +e.target.value })
                                        }}
                                        onClick={() => this.updatePage()}
                                    />
                                    de {totalPages}
                                </label>
                            </div>
                        </div>
                    </div>
                </Grid>
            </Row>
        )
    }

}

const mapStateToProps = state => ({ dto: state.occurrenceType.dto })
const mapDispatchToProps = dispatch => bindActionCreators({
    getList, showForm
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(OccurrenceTypeList)
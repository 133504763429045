import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import Home from '../pages/home/Home'
import PublicConsultResult from '../pages/public-consult-result/PublicConsultResult'
import Question from '../pages/question/Question'
import Register from '../pages/register/Register'

export default () => (
    <div className='content' style={{paddingTop: "70px"}}>
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/register' component={Register} />
            <Route path='/public-consult' component={PublicConsultResult} />
            <Route path='/question/:id' component={Question} />
            <Redirect from='*' to='/' />
        </Switch>
    </div>
)
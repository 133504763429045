import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    return (
        <Grid cols={props.cols}>
            <label
                className={props.className}
                style={{ marginRight: "5px"}}
            >
                {props.label}:
            </label>
            {props.input.value}
        </Grid>
    )
}

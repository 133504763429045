import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'

import UserLogo from '../../assets/user.png'
import { logout } from '../auth/AuthActions'

const UserMenu = props => {
    const [open, setOpen] = useState(false)
    const { name, email, expDate } = props.user

    return (
        <li onMouseLeave={_ => setOpen(!open)} className={`dropdown user user-menu ${open ? 'open' : ''}`}>
            <a
                onClick={_ => setOpen(!open)}
                aria-expanded={open ? 'true' : 'false'}
                className="dropdown-toggle"
                style={{ cursor: "pointer" }}
                data-toggle="dropdown">
                <img src={UserLogo} className="user-image" alt="User Image" />
                <span className="hidden-xs">{name}</span>
            </a>
            <ul className="dropdown-menu">
                <li className="user-header">
                    <img src={UserLogo} className="img-circle" alt="User Image Detail" />
                    <p>{name}<small>{email}</small><small>Sessão expira em {expDate}</small></p>
                </li>
                <li className="user-footer">
                    <div className="pull-right">
                        <Link
                            to="/"
                            onClick={props.logout}
                            className="btn btn-default btn-flat">
                            Sair
                        </Link>
                    </div>
                </li>
            </ul>
        </li>
    )

}

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch)

export default connect(null, mapDispatchToProps)(UserMenu)
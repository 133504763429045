import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    return (
        <Grid cols={props.cols}>
            <label
                className={props.className}
                style={props.style}
            >
                {props.input.value}
            </label>
        </Grid>
    )
}

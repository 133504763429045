import React from 'react'
import { connect } from 'react-redux'
import { formValueSelector } from 'redux-form'

import TabContent from '../../components/tab/TabContent'
import Timeline from '../../components/timeline/Timeline'

const MyWorkTabStories = props => {
    return (
        <TabContent id="tabStories">
            <div className="box-body no-padding">
                <Timeline list={props.list} />
            </div>

        </TabContent>
    )
}

const selector = formValueSelector("myWorkForm")
const mapStateToProps = state => ({
    list: selector(state, "stories")
})

export default connect(mapStateToProps)(MyWorkTabStories)
import consts from '../../main/consts'

const initialState = {
    selected: '',
    visible: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.TAB_SELECTED: return { ...state, selected: action.payload }
        case consts.REDUCERS.TAB_SHOWED: return { ...state, visible: action.payload }
        default: return state
    }
}

export function completeNumberWithZero(number, digits) {
    return number.toString().padStart(digits, "0")
}

export function formatDate(date) {
    const year = date.getFullYear()
    const month = completeNumberWithZero(date.getMonth() + 1, 2)
    const day = completeNumberWithZero(date.getDate(), 2)
    const hours = completeNumberWithZero(date.getHours(), 2)
    const minutes = completeNumberWithZero(date.getMinutes(), 2)
    const seconds = completeNumberWithZero(date.getSeconds(), 2)
    
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}
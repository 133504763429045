import React from 'react'
import Grid from '../layout/Grid'

export default props => {
    return (
        <Grid cols={props.cols}>
            <label htmlFor={props.name}>{props.label}:</label>
            <p
                {...props.input}
                className={props.className}
                style={{ whiteSpace: "pre-line"}}
            >
                {props.input.value}
            </p>
        </Grid>
    )
}

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { getAllActiveTypes } from '../../pages/occurrence-type/OccurrenceTypeActions'
import { setType } from '../../pages/register/RegisterActions'

class QuickAccess extends Component {

    componentDidMount() {
        this.props.getAllActiveTypes()
    }

    renderButtons() {
        const list = this.props.list || []

        return (
            list.map(type => {
                return (
                    <Link
                        type="button"
                        key={type.id}
                        className="btn btn-primary"
                        style={{ margin: "3px" }}
                        to="register"
                        onClick={() => this.props.setType(type)}
                    >
                        {type.description}
                    </Link>
                )
            })
        )
    }

    render() {
        return (
            <div style={{ margin: "50px 0px"}}>
                <div className="box-header text-center">
                    <h2>Acesso rápido</h2>
                </div>
                <div className="box-body text-center">
                    {this.renderButtons()}
                </div>
            </div>
        )
    }

}

const mapStateToProps = state => ({ list: state.occurrenceType.typesActiveList })
const mapDispatchToProps = dispatch => bindActionCreators({ getAllActiveTypes, setType }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(QuickAccess)
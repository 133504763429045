import React from 'react'
import { reduxForm } from 'redux-form'

import Tabs from '../../components/tab/Tabs'
import TabsContent from '../../components/tab/TabsContent'
import MyWorkActionButtons from './MyWorkActionButtons'
import MyWorkTabCore from './MyWorkTabCore'
import MyWorkTabForward from './MyWorkTabForward'
import MyWorkTabQuestions from './MyWorkTabQuestions'
import MyWorkTabAnswer from './MyWorkTabAnswer'
import MyWorkTabComplainer from './MyWorkTabComplainer'
import MyWorkTabStories from './MyWorkTabStories'

let MyWorkForm = props => {
    return (
        <form id="myWorkForm" role="form" onSubmit={props.handleSubmit}>
            <MyWorkActionButtons />
            <Tabs>
                <TabsContent>
                    <MyWorkTabCore />
                    <MyWorkTabForward />
                    <MyWorkTabQuestions />
                    <MyWorkTabStories />
                    <MyWorkTabAnswer />
                    <MyWorkTabComplainer />
                </TabsContent>
            </Tabs>
        </form >
    )
}

MyWorkForm = reduxForm({ form: "myWorkForm" })(MyWorkForm)
export default MyWorkForm
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

import consts from '../../main/consts'
import { api } from '../../main/api'
import { selectTab, showTabs } from '../../components/tab/TabActions'
import { getAllActiveTypes } from '../occurrence-type/OccurrenceTypeActions'

const ENDPOINT = "/subjects"

export function init() {
    return [
        showTabs('tabList'),
        selectTab('tabList'),
        getList(),
        getAllActiveTypes()
    ]
}

export function getList(description, page) {
    const pageParam = (page ? page : consts.PAGE_DEFAULT.INITIAL) - 1
    const sizeParam = consts.PAGE_DEFAULT.SIZE
    const descriptionParam = description ? `&description=${description}` : ''

    const params = `?page=${pageParam}&size=${sizeParam}${descriptionParam}`
    const request = api.get(`${consts.CONFIG.API_URL}${ENDPOINT}/pageable${params}`)
    return {
        type: consts.REDUCERS.SUBJECTS_FETCHED,
        payload: request
    }
}

export function getAllActiveSubjects() {
    const request = api.get(`${consts.CONFIG.API_URL}${ENDPOINT}/only-actives`)
    return {
        type: consts.REDUCERS.SUBJECTS_ACTIVES_FETCHED,
        payload: request
    }
}

export function create(values) {
    return submit(values, 'post')
}

export function update(values) {
    return submit(values, 'put')
}

export function remove(values) {
    return submit(values, 'delete')
}

export function submit(values, method) {
    return dispatch => {
        const id = values.id ? values.id : ''
        values.type = typeof (values.type) === 'string' ? JSON.parse(values.type) : values.type
        api[method](`${consts.CONFIG.API_URL}${ENDPOINT}/${id}`, values)
            .then(() => {
                toastr.success('Sucesso', 'Operação realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                toastr.error('Erro', e.response.data.error)
            })
    }
}

export function showForm(form, occurrenceType) {
    return [
        showTabs(form),
        selectTab(form),
        initialize('subjectForm', occurrenceType)
    ]
}
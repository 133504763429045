import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import LabelAndInput from '../../components/form/LabelAndInput'
import LabelAndSelect from '../../components/form/LabelAndSelect'
import { init } from './OccurrenceTypeActions'

const upper = value => value && value.toUpperCase()

class OccurrenceTypeForm extends Component {

    render() {
        const { handleSubmit, readOnly, updateMode } = this.props

        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field
                        name='id'
                        component={LabelAndInput}
                        label='ID'
                        cols='12 4'
                        placeholder='Informe o ID'
                        readOnly={readOnly || updateMode}
                        required
                        pattern="[A-Z]{3}"
                        msgInvalidPattern="Digite um ID válido! Ex.: AAA BBB CCC ABC"
                        maxLength="3"
                        normalize={upper}
                    />
                    <Field
                        name='active'
                        component={LabelAndSelect}
                        label='Ativo'
                        cols='12 4'
                        required
                        readOnly={readOnly}
                    >
                        <option value='' disabled>Selecione...</option>
                        <option key='true' value='true'>SIM</option>
                        <option key='false' value='false'>NÃO</option>
                    </Field>
                    <Field
                        name='color'
                        component={LabelAndSelect}
                        label='Cor'
                        cols='12 4'
                        readOnly={readOnly}
                        required
                    >
                        <option value='' disabled>Selecione...</option>
                        <option key='yellow' value='yellow' className='text-yellow'>AMARELA</option>
                        <option key='blue' value='blue' className='text-blue'>AZUL</option>
                        <option key='gray' value='gray' className='text-gray'>CINZA</option>
                        <option key='orange' value='orange' className='text-orange'>LARANJA</option>
                        <option key='lime' value='lime' className='text-lime'>LIMA</option>
                        <option key='olive' value='olive' className='text-olive'>OLIVA</option>
                        <option key='black' value='black' className='text-black'>PRETA</option>
                        <option key='fuchsia' value='fuchsia' className='text-fuchsia'>ROSA</option>
                        <option key='purple' value='purple' className='text-purple'>ROXA</option>
                        <option key='green' value='green' className='text-green'>VERDE</option>
                        <option key='red' value='red' className='text-red'>VERMELHA</option>
                    </Field>
                    <Field
                        name='description'
                        component={LabelAndInput}
                        label='Descrição'
                        cols='12'
                        placeholder='Informe a descrição'
                        readOnly={readOnly}
                        pattern=".{3,200}"
                        msgInvalidPattern="Digite uma descrição entre 3 a 200 caracteres!"
                        required
                        maxLength="200"
                        normalize={upper}
                    />
                </div>
                <div className='box-footer'>
                    <button
                        type='submit'
                        className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button
                        type='button'
                        className='btn btn-default'
                        onClick={this.props.init}>
                        Cancelar
                    </button>
                </div>
            </form>
        )
    }

}

OccurrenceTypeForm = reduxForm({ form: 'occurrenceTypeForm', destroyOnUnmount: false })(OccurrenceTypeForm)
const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch)

export default connect(null, mapDispatchToProps)(OccurrenceTypeForm)
import React, { Component } from 'react'
import { toastr } from 'react-redux-toastr'

import consts from '../../main/consts'
import Row from '../../components/layout/Row'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import { api } from '../../main/api'

class Question extends Component {

    constructor(props) {
        super(props)

        this.state = {
            question: {},
            status: '',
            answered: false,
            questionFetched: false
        }
        this.submit = this.submit.bind(this)
    }

    componentDidMount() {
        api.get(`${consts.CONFIG.API_URL}/questions/${this.props.match.params.id}`)
            .then(resp => this.setState({
                questionFetched: true,
                question: resp.data,
                status: resp.data.occurrence.status,
                answered: resp.data.hasOwnProperty('answer')
            }))
            .catch(() => this.setState({ questionFetched: true }))
    }

    submit() {
        if (!this.state.question.id) return

        const answerDate = new Date().toISOString()
        api.put(`${consts.CONFIG.API_URL}/questions/${this.state.question.id}`, { ...this.state.question, answerDate })
            .then(() => {
                this.setState({ answered: true })
            })
            .catch(e => {
                toastr.error('Erro', e.response.data.error)
            })
    }

    render() {
        if (!this.state.questionFetched) {
            return null
        } else if (!this.state.question.id) {
            return <h2>Questão não encontrada!</h2>
        } else if (this.state.answered) {
            return <h2>Questão respondida... Muito obrigado!</h2>
        } else if (["FIN", "DON"].includes(this.state.status)) {
            return <h2>Questão já finalizada... Muito obrigado!</h2>
        } else {
            return (
                <div>
                    <ContentHeader
                        title={"Resposta"}
                        subtitle=""
                    />
                    <Content>
                        <Row>
                            <div className="box-body no-padding">
                                <LabelAndTextarea
                                    name="answer"
                                    style={{ resize: "none" }}
                                    label="Digite aqui a sua resposta"
                                    cols="12"
                                    rows="10"
                                    required
                                    input={{ onChange: e => this.setState({ question: { ...this.state.question, answer: e.target.value } }) }}
                                />
                            </div>
                            <div className="pull-right no-padding">
                                <button
                                    type="button"
                                    className={"btn btn-success"}
                                    style={{ margin: "3px" }}
                                    disabled={!this.state.question.answer}
                                    onClick={() => this.submit()}
                                >
                                    <i className="fa fa-save" />
                                    Enviar resposta
                                </button>
                            </div>
                        </Row>
                    </Content>
                </div>
            )
        }
    }

}

export default Question
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from 'react-router-dom'

import consts from '../../main/consts'
import Grid from '../../components/layout/Grid'
import Row from '../../components/layout/Row'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import { api } from '../../main/api'
import { setOccurrence } from '../../components/public-consult/PublicConsultActions'

class MyOccurrences extends Component {

    constructor(props) {
        super(props)

        this.state = {
            list: []
        }
    }

    componentDidMount() {
        api.get(`${consts.CONFIG.API_URL}/occurrences/`)
            .then(resp => this.setState({ list: resp.data }))
    }

    renderRows() {
        const list = this.state.list || []

        return list.map(oc => (
            <tr key={oc.id}>
                <td><Link to="public-consult">
                    <i className={"fa fa-eye"} onClick={() => this.props.setOccurrence(oc)} />
                    </Link></td>
                <td><span className={`badge bg-${consts.OCCURRENCE_STATUS[oc.status].color}`}>
                    {consts.OCCURRENCE_STATUS[oc.status].text}
                </span></td>
                <td>{oc.protocol}</td>
                <td>{oc.subject.type.description}</td>
                <td>{new Date(oc.factDate).toLocaleString()}</td>
                <td>{new Date(oc.updatedAt).toLocaleString()}</td>
            </tr>
        ))
    }

    render() {
        return (
            <div>
                <ContentHeader
                    title="Minhas ocorrências"
                    subtitle=""
                />
                <Content>
                    <Row>
                        <Grid cols="12">
                            <div className="box">
                                <div className="box-body table-responsive no-padding">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th className="status-column">#</th>
                                                <th>Status</th>
                                                <th>Protocolo</th>
                                                <th>Tipo</th>
                                                <th>Data do fato</th>
                                                <th>Data da última atualização</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.renderRows()}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Grid>
                    </Row>
                </Content>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ setOccurrence }, dispatch)

export default connect(null, mapDispatchToProps)(MyOccurrences)
import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import consts from '../../main/consts'
import Row from '../../components/layout/Row'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import LabelAndValue from '../../components/form/LabelAndValue'
import LabelAndText from '../../components/form/LabelAndText'
import LabelAndFormatedDate from '../../components/form/LabelAndFormatedDate'
import If from '../../components/operator/If'
import { api } from '../../main/api'

const PublicConsultResult = props => {
    const dto = props.dto || {}

    if (dto.hasOwnProperty('answer') && !dto.hasOwnProperty('readAnswerDate')) {
        const readAnswerDate = new Date().toISOString()
        api.put(`${consts.CONFIG.API_URL}/occurrences/${dto.id}`, { ...dto, readAnswerDate })
    }

    const renderDetails = _ => {
        return dto.id ?
            <div>
                <ContentHeader
                    title="Ocorrência"
                    subtitle=""
                />
                <Content>
                    <Row>
                        <div className="box box-primary">
                            <div className="box-header">
                                <h3 className="box-title">Dados da ocorrência</h3>
                            </div>
                            <div className='box-body no-padding'>
                                <LabelAndValue
                                    input={{ value: dto.protocol }}
                                    label='Protocolo'
                                    cols='12 4'
                                />
                                <LabelAndValue
                                    input={{ value: dto.unit.description }}
                                    label='Unidade'
                                    cols='12'
                                />
                                <LabelAndValue
                                    input={{ value: dto.local }}
                                    label='Local'
                                    cols='12'
                                />
                                <LabelAndFormatedDate
                                    input={{ value: dto.factDate }}
                                    label='Data do fato'
                                    cols='12 6'
                                />
                                <LabelAndFormatedDate
                                    input={{ value: dto.createdAt }}
                                    label='Data de registro'
                                    cols='12 6'
                                />
                                <LabelAndText
                                    input={{ value: dto.description }}
                                    label='Descrição'
                                    rows='5'
                                    cols='12'
                                />
                            </div>
                        </div>
                        <div className={`box box-${dto.status === 'DON' ? 'success' : 'warning'}`}>
                            <div className="box-header">
                                <h3 className="box-title">Situação</h3>
                            </div>
                            <div className='box-body no-padding'>
                                <LabelAndValue
                                    input={{ value: consts.OCCURRENCE_STATUS[dto.status].text }}
                                    label='Status'
                                    cols='12 4'
                                />
                                <LabelAndFormatedDate
                                    input={{ value: dto.updatedAt }}
                                    label='Última atualização'
                                    cols='12 8'
                                />
                                <If test={dto.status === 'DON'}>
                                    <LabelAndText
                                        label="Resposta"
                                        cols="12"
                                        rows="5"
                                        input={{ value: dto.answer }}
                                    />
                                </If>
                            </div>
                        </div>
                    </Row>
                </Content>
            </div>
            : <h2 className="text-center">Ocorrência não encontrada</h2>
    }

    return (
        <>
            {renderDetails()}
            <If test={dto.id}>
                <div className='box-footer no-padding' style={{ marginBottom: "50px" }}>
                    <Link
                        type='button'
                        className='btn btn-default'
                        to="/">
                        <i className="fa fa-undo" />
                    Voltar
                </Link>
                    <button
                        type="button"
                        className='btn btn-info'
                        onClick={() => window.print()}>
                        <i className="fa fa-print" />
                    Imprimir
                </button>
                </div>
            </If>
        </>
    )
}

const mapStateToProps = state => ({ dto: state.publicConsult.dto })

export default connect(mapStateToProps)(PublicConsultResult)
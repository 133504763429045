import React from 'react'
import consts from '../../main/consts'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'

import LabelAndSelect from '../../components/form/LabelAndSelect'
import LabelAndInput from '../../components/form/LabelAndInput'
import { formatDate } from '../../main/utils'

let StatisticReportForm = props => {
    const { handleSubmit } = props
    const status = consts.OCCURRENCE_STATUS
    const limitDate = new Date()

    const renderTypesList = () => {
        const list = props.typeList || []
        return list.map((tp, i) => {
            return <option key={`type-${tp.id}`} value={tp.id}>{tp.description}</option>
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="box box-primary">
                <div className="box-header">
                    <p><strong>Filtros</strong></p>
                </div>
                <div className="box-body no-padding">
                    <Field
                        name='status'
                        component={LabelAndSelect}
                        label='Status'
                        cols='12 12 4'
                    >
                        <option key="status-null" value="">Todos</option>
                        <option key="status-WAI" value="WAI">{status.WAI.text}</option>
                        <option key="status-ANL" value="ANL">{status.ANL.text}</option>
                        <option key="status-TRE" value="TRE">{status.TRE.text}</option>
                        <option key="status-FIN" value="FIN">{status.FIN.text}</option>
                        <option key="status-DON" value="DON">{status.DON.text}</option>

                    </Field>
                    <Field
                        name='type'
                        component={LabelAndSelect}
                        label='Tipos de ocorrência'
                        cols='12 12 8'
                    >
                        <option key="type-null" value="">Todos</option>
                        {renderTypesList()}
                    </Field>
                    <Field
                        name='groupBy'
                        component={LabelAndSelect}
                        label='Agrupado por'
                        cols='12 12 4'
                        required
                    >
                        <option key="groupBy-null" value="" disabled>Selecione...</option>
                        <option key="groupBy-type-status" value="type, status">Tipo de ocorrência - Status</option>
                        <option key="groupBy-status-type" value="status, type">Status - Tipo de ocorrência</option>
                    </Field>
                    <Field
                        name='initialDateTime'
                        component={LabelAndInput}
                        label='Data inicial'
                        cols='12 6 4'
                        required
                        type="datetime-local"
                        max={formatDate(limitDate)}
                    />
                    <Field
                        name='finalDateTime'
                        component={LabelAndInput}
                        label='Data final'
                        cols='12 6 4'
                        required
                        type="datetime-local"
                        max={formatDate(limitDate)}
                    />
                </div>
                <div className="box-footer">
                    <button
                        type="submit"
                        className={"btn btn-primary pull-right"}>
                        <i className="fa fa-search" />
                        Visualizar
                    </button>
                </div>
            </div>
        </form>
    )

}

StatisticReportForm = reduxForm({ form: 'statisticReportForm' })(StatisticReportForm)
const mapStateToProps = state => ({
    typeList: state.occurrenceType.typesActiveList,
})

export default connect(mapStateToProps)(StatisticReportForm)
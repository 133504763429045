import React from 'react'

import MenuItem from './MenuItem'
import MenuTree from './MenuTree'
import If from '../../components/operator/If'

export default props => (
    <ul className='sidebar-menu'>
        <If test={props.user.isOmbudsman}>
            <MenuItem path='/' label='Dashboard' icon='dashboard' />
            <MenuItem path='my-work' label='Meu trabalho' icon='deaf' />
            <MenuTree label='Cadastros' icon='edit'>
                <MenuItem path='subjects' label='Assuntos' icon='comments-o' />
                <MenuItem path='occurrence-types' label='Tipos de ocorrência' icon='asterisk' />
            </MenuTree>
            <MenuTree label='Relatórios' icon='print'>
                <MenuItem path='statistic-report' label='Estatístico' icon='line-chart' />
            </MenuTree>
        </If>
        <If test={props.user.isCitizen}>
            <MenuItem path='register' label='Registrar nova ocorrência' icon='bullhorn' />
            <MenuItem path='/' label='Minhas ocorrências' icon='comments-o' />
        </If>
    </ul>
)
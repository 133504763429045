import React from 'react'
import { connect } from 'react-redux'
import Spinner from 'react-spinkit'

const Loading = ({ show }) => {
    return show ? (
        <div className="loading-content">
            <div className="loading-wrapper">
                <Spinner
                    name="ball-spin-fade-loader"
                    color="steelblue"
                    fadeIn="none"
                />
                <span className="loading-message">
                    Carregando ...
                </span>
            </div>
        </div>
    ) : null
}

const mapStateToProps = state => ({ 
    show: state.loading.show
})

export default connect(mapStateToProps)(Loading)
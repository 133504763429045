import React from 'react'

export default props =>
    <div className="progress-group">
        <span className="progress-text">
            {props.text}
            <span className={`label bg-${props.color}`} style={{ marginLeft: '5px', verticalAlign: 'text-top' }}>{props.detail}</span>
        </span>
        <span className="progress-number">{props.number}</span>
        <div className="progress progress-xs progress-striped active">
            <div className={`progress-bar bg-${props.color}`} style={{ width: props.percent + '%' }} />
        </div>
    </div>
import React from 'react'
import NotificationsMenu from './NotificationsMenu'
import UserMenu from './UserMenu'

import If from '../operator/If'

export default props =>
    <div className="navbar-custom-menu">
        <ul className="nav navbar-nav">
            <If test={props.user.isOmbudsman}>
                <NotificationsMenu />
            </If>
            <UserMenu {...props} />
        </ul>
    </div>
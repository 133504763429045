import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getNotReadAnswerList } from './TemplateActions'

import If from '../operator/If'

class NotificationsMenu extends Component {

    componentDidMount() {
        this.props.getNotReadAnswerList()
    }

    renderRows() {
        const list = this.props.list || []
        
        return list.map(question => (
            <li key={question.id}>
                <a>
                    <i className="fa fa-comments-o" />
                    {question.occurrence.protocol} <small className="text-muted">({question.email})</small>
                </a>
            </li>
        ))
    }

    render() {
        const listSize = this.props.list ? this.props.list.length : 0

        return (
            <li className="dropdown notifications-menu">
                <a className="dropdown-toggle" data-toggle="dropdown">
                    <i className="fa fa-bell-o"></i>
                    <If test={listSize > 0}>
                        <span className="label label-warning">{listSize}</span>
                    </If>
                </a>
                <ul className="dropdown-menu">
                    <li className="header">{`Você possui ${listSize} respostas não lidas`}</li>
                    <li>
                        <ul className="menu">
                            {this.renderRows()}
                        </ul>
                    </li>
                </ul>
            </li>
        )

    }

}

const mapStateToProps = state => ({ list: state.template.notReadAnswerList })
const mapDispatchToProps = dispatch => bindActionCreators({ getNotReadAnswerList }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsMenu)
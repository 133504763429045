import React from 'react'
import { connect } from 'react-redux'

const Footer = props => {
    const user = props.user || {}
    const marginLeft = user.userToken ? '' : '0px'
    
    return (
        <footer className='main-footer navbar-fixed-bottom' style={{ marginLeft }}>
            <strong>
                Celk Sistemas - Todos os direitos reservados
        </strong>
        </footer>
    )
}

const mapStateToProps = state => ({ user: state.auth.user })

export default connect(mapStateToProps, null)(Footer)
import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

import consts from '../../main/consts'
import { selectTab, showTabs } from '../../components/tab/TabActions'
import { getAllActiveTypes } from '../occurrence-type/OccurrenceTypeActions'
import { api } from '../../main/api'

export function init() {
    return [
        showTabs('tabFilter'),
        selectTab('tabFilter'),
        setStatisticData([]),
        getAllActiveTypes(),
        initialize('statisticReportForm', null)
    ]
}

export function submit(values) {
    return dispatch => {
        if (values.initialDateTime > values.finalDateTime) {
            toastr.error('Erro', "A data inicial deverá ser menor ou igual a final")
            return
        }
        
        values.initialDateTime = new Date(values.initialDateTime).toISOString()
        values.finalDateTime = new Date(values.finalDateTime).toISOString()
        api.post(`${consts.CONFIG.API_URL}/occurrences/statistics-report`, values)
            .then(resp => {
                dispatch(showResult(values, resp.data))
            })
            .catch(e => {
                toastr.error('Erro', e.response.data.error)
            })
    }
}

function setFilters(filters) {
    return {
        type: consts.REDUCERS.STATISTIC_FILTERS_FETCHED,
        payload: filters
    }
}

function setStatisticData(data) {
    return {
        type: consts.REDUCERS.STATISTIC_DATA_FETCHED,
        payload: data
    }
}

function showResult(filters, data) {
    return [
        setFilters(filters),
        setStatisticData(data),
        showTabs('tabList'),
        selectTab('tabList')
    ]
}
import React, { Component } from 'react'
import BoxCard from './BoxCard'

class Box extends Component {

    renderCards() {
        const list = this.props.list || []
        return list.map(oc => (
            <BoxCard key={oc.id} occurrence={oc} />
        ))
    }

    render() {
        return (
            <div className="box-list">
                <div className="box box-primary box-solid">
                    <div className="box-header with-border">
                        <h3 className="box-title">{this.props.title}</h3>
                        <div className="box-tools pull-right">
                            <span
                                data-toggle="tooltip"
                                className="badge bg-light-blue"
                                data-original-title={`${this.props.total} ocorrências`}
                            >
                                {this.props.list.length}
                            </span>
                        </div>
                    </div>
                    <div className="box-body">
                        {this.renderCards()}
                    </div>
                </div>
            </div>
        )
    }

}

export default Box

import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toastr } from 'react-redux-toastr'
import { withRouter } from 'react-router-dom'

import Grid from '../layout/Grid'
import { setOccurrence } from './PublicConsultActions'
import consts from '../../main/consts'
import { api } from '../../main/api'

const PublicConsult = props => {
    const [protocol, setProtocol] = useState("")
    const [validationKey, setValidationKey] = useState("")

    const onClickFindButton = async _ => {
        if (!protocol || !validationKey) {
            toastr.error('Campos obrigatórios', 'Favor informar o protocolo e chave de acesso antes de consultar.')
            return
        }

        const occurrence = await 
            api.get(`${consts.CONFIG.API_URL}/occurrences/by-protocol?protocol=${protocol}&validationKey=${validationKey}`)
            .then(resp => { return resp.data })
            .catch(() => {
                toastr.error('Consultar processo', 'Ocorrência não encontrada com o protocolo e chave de acesso informados.')
            })
        if (!occurrence) { return }
        
        props.setOccurrence(occurrence)
        props.history.push("public-consult")
    }

    return (
        <div style={{ backgroundColor: "#ADD8E6" }}>
            <div className="box-header text-center">
                <h2>Acompanhe seu processo</h2>
            </div>
            <div className="box-body">
                <Grid cols="12 4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Protocolo"
                        cols="12 4"
                        style={{ margin: "3px" }}
                        onChange={e => setProtocol(e.target.value)}
                    />
                </Grid>
                <Grid cols="12 4">
                    <input
                        className="form-control"
                        type="text"
                        placeholder="Chave de acesso"
                        cols="12 4"
                        style={{ margin: "3px" }}
                        onChange={e => setValidationKey(e.target.value)}
                    />
                </Grid>
                <Grid cols="12 4">
                    <button
                        type="button"
                        className="btn btn-primary btn-block btn-flat"
                        style={{ margin: "3px" }}
                        onClick={onClickFindButton}>
                        Consultar processo
                    </button>
                </Grid>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => bindActionCreators({ setOccurrence }, dispatch)

export default withRouter(connect(null, mapDispatchToProps)(PublicConsult))
import React from 'react'
import { Switch, Route, Redirect } from 'react-router'

import If from '../components/operator/If'
import Dashboard from '../pages/dashboard/Dashboard'
import MyWork from '../pages/my-work/MyWork'
import OccurrenceType from '../pages/occurrence-type/OccurrenceType'
import Subject from '../pages/subject/Subject'
import PrintVersion from '../pages/print-version/PrintVersion'
import PublicConsultResult from '../pages/public-consult-result/PublicConsultResult'
import Register from '../pages/register/Register'
import MyOccurrences from '../pages/my-occurrences/MyOccurrences'
import StatisticReport from '../pages/statistic-report/StatisticReport'

export default props => (
    <div className='content-wrapper'>
        <If test={props.user.isOmbudsman}>
            <Switch>
                <Route exact path='/' component={Dashboard} />
                <Route path='/my-work' component={MyWork} />
                <Route path='/occurrence-types' component={OccurrenceType} />
                <Route path='/subjects' component={Subject} />
                <Route path='/print-version' component={PrintVersion} />
                <Route path='/statistic-report' component={StatisticReport} />
                <Redirect from='*' to='/' />
            </Switch>
        </If>
        <If test={props.user.isCitizen}>
        <Switch>
                <Route exact path='/' component={MyOccurrences} />
                <Route path='/public-consult' component={PublicConsultResult} />
                <Route path='/register' component={Register} />
                <Redirect from='*' to='/' />
            </Switch>
        </If>
    </div>
)
import consts from '../../main/consts'

const initialState = {
    show: false
}

export default (state = initialState, action) => {
    if (action.type === consts.REDUCERS.LOADING) {
        return { ...state, show: action.payload }
    }
    
    return state
}    
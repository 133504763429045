import { toastr } from 'react-redux-toastr'
import { initialize } from 'redux-form'

import consts from '../../main/consts'
import { api } from '../../main/api'
import { selectTab, showTabs } from '../../components/tab/TabActions'
import { showModal, hideModal } from '../../components/modal/ModalActions'
import { getNotReadAnswerList } from '../../components/template/TemplateActions'

const ENDPOINT = "/occurrences"

export function init() {
    return [
        showTabs('tabCore'),
        selectTab('tabCore'),
        hideModal(),
        setQuestion({}),
        getList()
    ]
}

export function getList() {
    const request = api.get(`${consts.CONFIG.API_URL}${ENDPOINT}`)
    return {
        type: consts.REDUCERS.OCCURRENCES_FETCHED,
        payload: request
    }
}

export function submit(values) {
    return dispatch => {
        api.put(`${consts.CONFIG.API_URL}${ENDPOINT}/${values.id}`, values)
            .then(() => {
                toastr.success('Sucesso', 'Operação realizada com sucesso.')
                dispatch(init())
            })
            .catch(e => {
                toastr.error('Erro', e.response.data.error)
            })
    }
}

export function showForm(occurrence) {
    return [
        showModal(),
        initialize('myWorkForm', occurrence)
    ]
}

export function changeTab(tab) {
    return [
        showTabs(tab),
        selectTab(tab)
    ]
}

export function setQuestionAndUpdate(question) {
    return [
        updateQuestionAndList(question),
        setQuestion(question)
    ]
}

export function setQuestion(question) {
    return {
        type: consts.REDUCERS.QUESTION_FETCHED,
        payload: question
    }
}

export function updateQuestionAndList(question) {
    return dispatch => {
        if (question.answer && !question.readAnswerDate) {
            const readAnswerDate = new Date().toISOString()
            api.put(`${consts.CONFIG.API_URL}/questions/${question.id}`, { ...question, readAnswerDate })
                .then(() => {
                    dispatch(getNotReadAnswerList())
                })
        }
        dispatch(getNotReadAnswerList())
    }
}
import React from 'react'
import Grid from '../layout/Grid'

export default props =>
    <Grid cols={props.cols}>
        <div className="info-box">
            <span className={`info-box-icon bg-${props.color}`}>
                <i className={`fa fa-${props.icon}`} />
            </span>
            <div className="info-box-content">
                <span className="info-box-text">{props.text}</span>
                <span className="info-box-number">{props.number}</span>
                <div className="progress">
                    <div className={`progress-bar bg-${props.color}`} style={{width: props.percent + '%'}} />
                </div>
                <span className="progress-description">{props.description}</span>
            </div>
        </div>
    </Grid>

import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, formValueSelector, change, submit } from 'redux-form'

import consts from '../../main/consts'
import TabContent from '../../components/tab/TabContent'
import LabelAndTextarea from '../../components/form/LabelAndTextarea'
import { api } from '../../main/api'

const MyWorkTabAnswer = props => {

    function isReadyOnly() {
        return props.status === "DON"
    }

    const changeStatusToDone = async _ => {
        const storie = {
            description: "Ocorrência concluída",
            occurrence: { id: props.id },
            status: "DON"
        }

        await props.change("myWorkForm", "status", "DON")
        await api.post(`${consts.CONFIG.API_URL}/stories`, storie)
        props.submit("myWorkForm")
    }

    return (
        <TabContent id="tabAnswer">
            <div className='box-body no-padding'>
                <Field
                    name='answer'
                    component={LabelAndTextarea}
                    style={{ resize: "none" }}
                    label='Resposta'
                    placeholder="Digite aqui a reposta que será apresentada ao cidadão"
                    rows='10'
                    cols='12'
                    required
                    readOnly={isReadyOnly()}
                />
            </div>
            <div className='box-footer no-padding'>
                <div className="pull-right">
                    <button
                        disabled={isReadyOnly() || !props.answer}
                        type="submit"
                        className="btn btn-warning btn-sm"
                    >
                        <i className="fa fa-save" />
                        Salvar
                    </button>
                    <button
                        disabled={isReadyOnly() || !props.answer}
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={changeStatusToDone}
                    >
                        <i className="fa fa-gavel" />
                        Concluir
                    </button>
                </div>
            </div>
        </TabContent>
    )
}

const selector = formValueSelector("myWorkForm")
const mapStateToProps = state => ({
    status: selector(state, "status"),
    answer: selector(state, "answer"),
    id: selector(state, "id")
})
const mapDispatchToProps = dispatch => bindActionCreators({ change, submit }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyWorkTabAnswer)
import consts from '../../main/consts'

const initialState = {
    dto: {},
    subjectsActiveList: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.SUBJECTS_FETCHED: return { ...state, dto: action.payload.data }
        case consts.REDUCERS.SUBJECTS_ACTIVES_FETCHED: return { ...state, subjectsActiveList: action.payload.data }
        default: return state
    }
}    
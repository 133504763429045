import consts from '../../main/consts'

export function showModal() {
    return {
        type: consts.REDUCERS.MODAL_SHOW,
        payload: true
    }
}

export function hideModal() {
    return {
        type: consts.REDUCERS.MODAL_HIDE,
        payload: false
    }
}
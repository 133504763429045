import consts from '../../main/consts'
import { api } from '../../main/api'

function getUnit(careUnit) {
    return api.get(`${consts.CONFIG.API_URL}/care-units/${careUnit}`)
        .then(resp => { return resp.data })
        .catch(_ => { return {} })
}

export async function setOccurrence(occurrence) {
    const unit = await getUnit(occurrence.careUnit)
    return {
        type: consts.REDUCERS.OCCURRENCE_FETCHED,
        payload: { ...occurrence, unit }
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Field, reduxForm } from 'redux-form'
import LabelAndInput from '../../components/form/LabelAndInput'
import LabelAndSelect from '../../components/form/LabelAndSelect'
import { init } from './SubjectActions'

class SubjectForm extends Component {

    renderTypesList() {
        const list = this.props.typesActiveList || []
        return list.map(ot => {
            return <option key={ot.id} value={JSON.stringify(ot)}>{ot.description}</option>
        })
    }

    render() {
        const { handleSubmit, readOnly, updateMode } = this.props

        return (
            <form role='form' onSubmit={handleSubmit}>
                <div className='box-body'>
                    <Field
                        name='id'
                        component={LabelAndInput}
                        label='ID'
                        cols='12 2'
                        readOnly={true}
                    />
                    <Field
                        name='active'
                        component={LabelAndSelect}
                        label='Ativo'
                        cols='12 2'
                        readOnly={readOnly}
                        required
                    >
                        <option value='' disabled>Selecione...</option>
                        <option key='true' value='true'>SIM</option>
                        <option key='false' value='false'>NÃO</option>
                    </Field>
                    <Field
                        name='type'
                        component={LabelAndSelect}
                        label='Tipo de ocorrência'
                        cols='12 8'
                        readOnly={readOnly || updateMode}
                        required
                    >
                        <option value='' disabled>Selecione...</option>
                        {this.renderTypesList()}
                    </Field>
                    <Field
                        name='description'
                        component={LabelAndInput}
                        label='Descrição'
                        cols='12'
                        placeholder='Informe a descrição'
                        readOnly={readOnly}
                        required
                        pattern=".{3,200}"
                        msgInvalidPattern="Digite uma descrição entre 3 a 200 caracteres!"
                        maxLength="200"
                    />
                </div>
                <div className='box-footer'>
                    <button
                        type='submit'
                        className={`btn btn-${this.props.submitClass}`}>
                        {this.props.submitLabel}
                    </button>
                    <button
                        type='button'
                        className='btn btn-default'
                        onClick={this.props.init}>
                        Cancelar
                    </button>
                </div>
            </form>
        )
    }

}

SubjectForm = reduxForm({ form: 'subjectForm', destroyOnUnmount: false })(SubjectForm)
const mapStateToProps = state => ({ typesActiveList: state.occurrenceType.typesActiveList })
const mapDispatchToProps = dispatch => bindActionCreators({ init }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(SubjectForm)
import React from 'react'
import { HashRouter } from 'react-router-dom';

import Header from '../components/template/Header'
import Footer from '../components/template/Footer'
import Routes from './PublicRoutes';
import Message from '../components/message/Message';
import Loading from '../components/loading/Loading';

export default () =>
    <HashRouter>
        <div className="wrapper">
            <Header />
            <Routes />
            <Footer />
            <Message />
            <Loading />
        </div>
    </HashRouter>
import consts from '../../main/consts'

const initialState = {
    dto: {},
    typesActiveList: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case consts.REDUCERS.OCCURRENCE_TYPES_FETCHED: return { ...state, dto: action.payload.data }
        case consts.REDUCERS.OCCURRENCE_TYPES_ACTIVES_FETCHED: return { ...state, typesActiveList: action.payload.data }
        default: return state
    }
}    
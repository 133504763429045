import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Row from '../../components/layout/Row'
import Modal from '../../components/modal/Modal'
import Content from '../../components/template/Content'
import ContentHeader from '../../components/template/ContentHeader'
import Box from './Box'
import { init, submit } from './MyWorkActions'
import Form from './MyWorkForm.jsx'

class MyWork extends Component {

    componentWillMount() {
        this.props.init()
    }

    getStatusList(status) {
        const list = this.props.list || []
        return list.filter(occurrence => occurrence.status === status)
    }

    render() {
        return (
            <div>
                <ContentHeader
                    title="Meu trabalho"
                    subtitle=""
                />
                <Content>
                    <Row>
                        <Modal
                            bodyStyle={{ padding: "0px" }}
                            hideHeader
                        >
                            <Form onSubmit={this.props.submit} />
                        </Modal>
                        <div className="board">
                            <Box
                                title="Aguardando análise"
                                list={this.getStatusList("WAI")}
                            />
                            <Box
                                title="Em análise"
                                list={this.getStatusList("ANL")}
                            />
                            <Box
                                title="Análise e tratamento"
                                list={this.getStatusList("TRE")}
                            />
                            <Box
                                title="Finalizado"
                                list={this.getStatusList("FIN")}
                            />
                            <Box
                                title="Concluído"
                                list={this.getStatusList("DON")}
                            />
                        </div>
                    </Row>
                </Content>
            </div>
        )
    }

}

const mapStateToProps = state => ({
    list: state.myWork.list
})
const mapDispatchToProps = dispatch => bindActionCreators({ init, submit }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(MyWork)
const isProd = process.env.NODE_ENV === 'production';

export default {
    CONFIG: {
        DEBUG_LOG: !isProd,
        API_URL: isProd? '${API_URL}':'http://localhost:8080/cms-api/v1',
        AUTH_API_URL: isProd ? '${AUTH_API_URL}':'https://auth-service.apoio.celk.info/user',
        TOKEN_REF: '_celk_cms',
        KEYCLOAK: {
            tokenUrl: isProd? '${KEYCLOAK_URL}/protocol/openid-connect/token':'https://keycloak.apoio.celk.info/auth/realms/celk-realm/protocol/openid-connect/token',
            clientId: isProd? '${KEYCLOAK_CLIENT_ID}':'cms',
            clientSecret: isProd? '${KEYCLOAK_CLIENT_SECRET}':'5db1cb3d-6202-45cb-9cbe-7872c2f20554'
        }
    },

    PAGE_DEFAULT: {
        INITIAL: 1,
        SIZE: 5
    },

    OCCURRENCE_STATUS: {
        WAI: {
            text: 'Aguardando análise',
            icon: 'clock-o',
            color: 'purple'
        },
        ANL: {
            text: 'Em análise',
            icon: 'search',
            color: 'orange'
        },
        TRE: {
            text: 'Análise e tratamento',
            icon: 'comments-o',
            color: 'yellow'
        },
        FIN: {
            text: 'Finalizado',
            icon: 'commenting-o',
            color: 'lime'
        },
        DON: {
            text: 'Concluído',
            icon: 'thumbs-o-up',
            color: 'green'
        }        
    },    

    REDUCERS: {
        TAB_SELECTED: 'TAB_SELECTED',
        TAB_SHOWED: 'TAB_SHOWED',
        MODAL_SHOW: 'MODAL_SHOW',
        MODAL_HIDE: 'MODAL_HIDE',
        LOADING: 'LOADING',
        USER_FETCHED: 'USER_FETCHED',
        OCCURRENCES_FETCHED: 'OCCURRENCES_FETCHED',
        OCCURRENCE_TYPES_FETCHED: 'OCCURRENCE_TYPES_FETCHED',
        OCCURRENCE_TYPES_ACTIVES_FETCHED: 'OCCURRENCE_TYPES_ACTIVES_FETCHED',
        OCCURRENCE_TYPE_FETCHED: 'OCCURRENCE_TYPE_FETCHED',
        SUBJECTS_FETCHED: 'SUBJECTS_FETCHED',
        SUBJECTS_ACTIVES_FETCHED: 'SUBJECTS_ACTIVES_FETCHED',
        DASHBOARD_FETCHED: 'DASHBOARD_FETCHED',
        OCCURRENCE_REGISTER_TYPE: 'OCCURRENCE_REGISTER_TYPE',
        OCCURRENCE_FETCHED: 'OCCURRENCE_FETCHED',
        OCCURRENCE_CREATED: 'OCCURRENCE_CREATED',
        UNITS_FETCHED: 'UNITS_FETCHED',
        QUESTION_FETCHED: 'QUESTION_FETCHED',
        NOT_READ_ANSWER_FETCHED: 'NOT_READ_ANSWER_FETCHED',
        STATISTIC_FILTERS_FETCHED: 'STATISTIC_FILTERS_FETCHED',
        STATISTIC_DATA_FETCHED: 'STATISTIC_DATA_FETCHED'
    },

    ROLES: {
        OMBUDSMAN: 'ombudsman',
        CITIZEN: 'citizen'
    }    
}